import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const AppDevelopment = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const ScrollTriggeredSection = ({ children, className }) => {
    const [sectionRef, sectionInView] = useInView({
      triggerOnce: true,
      threshold: 0.1,
    });
    const sectionControls = useAnimation();

    useEffect(() => {
      if (sectionInView) {
        sectionControls.start('visible');
      }
    }, [sectionControls, sectionInView]);

    return (
      <motion.section
        ref={sectionRef}
        initial="hidden"
        animate={sectionControls}
        variants={{
          hidden: { opacity: 0 },
          visible: {
            opacity: 1,
            transition: {
              staggerChildren: 0.3,
              duration: 0.8
            }
          }
        }}
        className={className}
      >
        {children}
      </motion.section>
    );
  };

  const ProcessItem = ({ icon, title, description }) => {
    const [itemRef, itemInView] = useInView({
      triggerOnce: true,
      threshold: 0.1,
    });
    const itemControls = useAnimation();

    useEffect(() => {
      if (itemInView) {
        itemControls.start('visible');
      }
    }, [itemControls, itemInView]);

    return (
      <motion.div 
        ref={itemRef}
        className="bg-white p-6 rounded-lg shadow-lg"
        initial="hidden"
        animate={itemControls}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { 
            opacity: 1, 
            y: 0,
            transition: {
              duration: 0.8,
              ease: "easeOut"
            }
          }
        }}
        whileHover={{ scale: 1.05 }}
      >
        <div className="text-[#10ac84] text-4xl mb-4">{icon}</div>
        <h3 className="text-xl font-semibold text-gray-800 mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </motion.div>
    );
  };

  const techStacks = [
    { path: '/images/tech/react.png', name: 'React Native' },
    { path: '/images/tech/flutter.png', name: 'Flutter' },
    { path: '/images/tech/xamarine.png', name: 'Xamarin' },
    { path: '/images/tech/ios.png', name: 'iOS' },
    { path: '/images/tech/android.png', name: 'Android' },
  ];

  const processes = [
    {
      icon: "📱",
      title: "Cross-Platform Development",
      description: "We develop apps that work seamlessly across multiple platforms, ensuring a wider reach for your application."
    },
    {
      icon: "🔒",
      title: "Security",
      description: "We prioritize the security of your app, implementing robust measures to protect user data and ensure safe transactions."
    },
    {
      icon: "🚀",
      title: "Performance Optimization",
      description: "Our apps are optimized for speed and efficiency, providing a smooth user experience across all devices."
    }
  ];

  const processItems = [
    { emoji: "🔄", title: "Project Communication" },
    { emoji: "📋", title: "Requirements Management" },
    { emoji: "🔄", title: "Change Management" },
    { emoji: "⚠️", title: "Risk Management" },
    { emoji: "✅", title: "Quality Assurance" },
    { emoji: "🤝", title: "Competence Transfer" },
  ];

  return (
    <div className="overflow-hidden">
      <section className="relative bg-gray-900 text-white py-24">
        <div 
          className="absolute inset-0 bg-cover bg-center z-0"
          style={{
            backgroundImage: "url('/images/app-development-bg.jpg')",
            filter: "brightness(33%)",
            backgroundSize: "100%",
            backgroundPosition: "center 40%",
          }}
        ></div>
        <div className="absolute inset-0 bg-blue-500 z-10 mix-blend-overlay opacity-35"></div>
        <div className="container mx-auto px-4 max-w-6xl relative z-20">
          <motion.h1 
            className="text-5xl font-bold mb-6"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            App Development
          </motion.h1>
          <nav className="text-sm mb-4">
            <ol className="list-none p-0 inline-flex">
              <li className="flex items-center">
                <Link to="/" className="text-gray-400 hover:text-white">Home</Link>
                <span className="mx-2 text-gray-600">•</span>
              </li>
              <li>
                <span className="text-gray-300">App Development</span>
              </li>
            </ol>
          </nav>
        </div>
      </section>

      <ScrollTriggeredSection className="py-24 bg-gray-100">
        <div className="container mx-auto px-4 max-w-6xl">
          <div className="flex flex-col md:flex-row items-stretch space-y-12 md:space-y-0 md:space-x-12">
            <motion.div 
              className="md:w-1/2 flex flex-col justify-center"
              variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { 
                  opacity: 1, 
                  x: 0,
                  transition: {
                    duration: 0.8,
                    ease: "easeOut"
                  }
                }
              }}
            >
              <h2 className="text-2xl font-semibold text-[#10ac84] mb-4">Mobile App Development Excellence</h2>
              <h3 className="text-4xl font-bold text-gray-800 mb-6">
                Transforming Your Ideas into Powerful Mobile Solutions
              </h3>
              <p className="text-gray-600 mb-4">
                In today's mobile-first world, having a strong presence on mobile platforms is crucial for business success. At SmartTech Devs, we specialize in creating custom mobile solutions that exceed your expectations. Our skilled developers work tirelessly to turn your ideas into reliable, scalable, and user-friendly mobile applications.
              </p>
              <p className="text-gray-600 mb-4">
                From intuitive user interfaces to robust backend systems, we offer comprehensive mobile development services tailored to your specific needs. Our solutions are built for performance, designed to impress, and ready to grow with your business.
              </p>
            </motion.div>
            <motion.div 
              className="md:w-1/2 flex items-center"
              variants={{
                hidden: { opacity: 0, x: 50 },
                visible: { 
                  opacity: 1, 
                  x: 0,
                  transition: {
                    duration: 0.9,
                    ease: "easeOut"
                  }
                }
              }}
            >
              <motion.img 
                src="/images/app-development-showcase.jpg" 
                alt="App Development Showcase" 
                className="rounded-lg shadow-xl w-full h-full object-cover"
                animate={{ y: [0, -10, 0] }}
                transition={{ 
                  duration: 4,
                  repeat: Infinity,
                  repeatType: "reverse",
                  ease: "easeInOut"
                }}
              />
            </motion.div>
          </div>
        </div>
      </ScrollTriggeredSection>

      <ScrollTriggeredSection className="py-24 bg-white">
        <div className="container mx-auto px-4 max-w-6xl">
          <motion.p 
            className="text-center text-[#10ac84] text-lg font-semibold mb-4"
            variants={{
              hidden: { opacity: 0, y: -20 },
              visible: { 
                opacity: 1, 
                y: 0,
                transition: {
                  duration: 0.5,
                  ease: "easeOut"
                }
              }
            }}
          >
            Tools & Technologies
          </motion.p>
          <motion.h2 
            className="text-center text-4xl font-bold text-gray-800 mb-12"
            variants={{
              hidden: { opacity: 0, y: -30 },
              visible: { 
                opacity: 1, 
                y: 0,
                transition: {
                  duration: 1,
                  delay: 0.3,
                  ease: "easeOut"
                }
              }
            }}
          >
            Tech Stacks For Modern-Day Mobile Apps
          </motion.h2>
          <div className="flex justify-center items-center space-x-8 max-w-4xl mx-auto">
            {techStacks.map((tech, index) => (
              <motion.div 
                key={index} 
                className="flex flex-col items-center"
                variants={{
                  hidden: { opacity: 0, y: 50 },
                  visible: { 
                    opacity: 1, 
                    y: 0,
                    transition: {
                      duration: 0.5,
                      delay: index * 0.1,
                      ease: "easeOut"
                    }
                  }
                }}
              >
                <img 
                  src={tech.path} 
                  alt={tech.name} 
                  className="h-12 mb-2 transition-transform duration-300 ease-in-out transform hover:scale-110" 
                />
                <span className="text-sm text-gray-600">{tech.name}</span>
              </motion.div>
            ))}
          </div>
        </div>
      </ScrollTriggeredSection>

      <ScrollTriggeredSection className="py-24 bg-gray-100">
        <div className="container mx-auto px-4 max-w-6xl">
          <motion.p 
            className="text-center text-[#10ac84] text-lg font-semibold mb-4"
            variants={{
              hidden: { opacity: 0, y: -20 },
              visible: { 
                opacity: 1, 
                y: 0,
                transition: {
                  duration: 1,
                  delay: 0.2,
                  ease: "easeOut"
                }
              }
            }}
          >
            How we do it
          </motion.p>
          <motion.h2 
            className="text-4xl font-bold text-center text-gray-800 mb-12"
            variants={{
              hidden: { opacity: 0, y: -30 },
              visible: { 
                opacity: 1, 
                y: 0,
                transition: {
                  duration: 1,
                  delay: 0.2,
                  ease: "easeOut"
                }
              }
            }}
          >
            Syncing Business Vision with Mobile Innovation
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {processes.map((process, index) => (
              <ProcessItem key={index} {...process} />
            ))}
          </div>
        </div>
      </ScrollTriggeredSection>

      <ScrollTriggeredSection className="py-24 bg-white">
        <div className="container mx-auto px-4 max-w-6xl">
          <motion.p 
            className="text-[#10ac84] text-lg font-semibold mb-4"
            variants={{
              hidden: { opacity: 0, y: -20 },
              visible: { 
                opacity: 1, 
                y: 0,
                transition: {
                  duration: 1,
                  delay: 0.2,
                  ease: "easeOut"
                }
              }
            }}
          >
            Project Management
          </motion.p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <motion.div 
              className="md:col-span-1"
              variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { 
                  opacity: 1, 
                  x: 0,
                  transition: {
                    duration: 0.8,
                    ease: "easeOut"
                  }
                }
              }}
            >
              <h2 className="text-3xl font-bold text-gray-800 mb-4">Our App Development Process</h2>
              <p className="text-gray-600">
                SmartTech Devs brings years of hands-on experience in mobile app development. We've refined our process to be efficient, transparent, and predictable, ensuring the best results for your project.
              </p>
            </motion.div>
            <div className="md:col-span-2 grid grid-cols-2 md:grid-cols-3 gap-6">
              {processItems.map((item, index) => (
                <motion.div 
                  key={index} 
                  className="bg-gray-100 p-6 rounded-lg"
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { 
                      opacity: 1, 
                      y: 0,
                      transition: {
                        duration: 0.8,
                        delay: index * 0.1,
                        ease: "easeOut"
                      }
                    }
                  }}
                >
                  <div className="text-4xl mb-2">{item.emoji}</div>
                  <h3 className="text-lg font-semibold text-gray-800">{item.title}</h3>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </ScrollTriggeredSection>

      <ScrollTriggeredSection className="relative py-24 bg-gray-900 text-white overflow-hidden">
  <div
    className="absolute inset-0 bg-cover bg-center z-0"
    style={{
      backgroundImage: "url('/images/app-development-bg2.jpg')",
      filter: "brightness(35%)",
      backgroundSize: "cover",
      backgroundPosition: "center 40%",
    }}
  ></div>
  <div className="absolute inset-0 bg-blue-500 z-10 mix-blend-overlay opacity-35"></div>
  <div className="container mx-auto px-4 max-w-6xl relative z-20">
    <div className="flex flex-col md:flex-row justify-between items-center">
      <motion.div
        className="mb-8 md:mb-0"
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { 
            opacity: 1, 
            x: 0,
            transition: {
              duration: 0.8,
              ease: "easeOut"
            }
          }
        }}
      >
        <h2 className="text-4xl font-bold mb-4">Ready to build your app?</h2>
        <p className="text-xl max-w-2xl">
          Let's turn your app idea into reality. Our team is ready to bring your vision to life.
        </p>
      </motion.div>
      <motion.div
        variants={{
          hidden: { opacity: 0, x: 50 },
          visible: { 
            opacity: 1, 
            x: 0,
            transition: {
              duration: 0.5,
              ease: "easeOut"
            }
          }
        }}
      >
        <Link
          to="/contact"
          className="bg-[#10ac84] text-white py-3 px-8 rounded-lg text-lg font-semibold hover:bg-[#0d8b6a] transition duration-300"
        >
          Start Your Project
        </Link>
      </motion.div>
    </div>
  </div>
</ScrollTriggeredSection>
      </div>
    );
  };
  
  export default AppDevelopment;