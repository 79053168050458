import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-gray-900 text-white py-12">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    <div className="mb-8 md:mb-0">
                        <img src="/images/logo/green-logo.png" alt="SmartTech Devs Logo" className="h-12 mb-4" />
                        <p className="text-sm text-gray-400">Transforming ideas into digital reality. We create cutting-edge software solutions that empower businesses.</p>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold mb-4 text-[#10ac84]">Quick Links</h3>
                        <ul className="space-y-2">
                            {['Home', 'About Us', 'Showcase', 'Contact'].map((item) => (
                                <li key={item}>
                                    <Link to={item === 'Home' ? '/' : `/${item.toLowerCase().replace(' ', '-')}`} className="text-gray-400 hover:text-[#10ac84] transition duration-300">{item}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold mb-4 text-[#10ac84]">Services</h3>
                        <ul className="space-y-2">
                            {[
                                { name: 'Web Development', path: 'web-development' },
                                { name: 'App Development', path: 'app-development' },
                                { name: 'UI/UX Design', path: 'ui-ux-design' },
                                { name: 'Generative AI', path: 'generative-ai' }
                            ].map((service) => (
                                <li key={service.name}>
                                    <Link to={`/services/${service.path}`} className="text-gray-400 hover:text-[#10ac84] transition duration-300">{service.name}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold mb-4 text-[#10ac84]">Contact Us</h3>
                        <p className="mb-2 text-gray-400">Gilgit: Naveed Shaheed Road, Zulfiqarabad Gilgit</p>
                        <p className="mb-2 text-gray-400">Karachi: 4th Floor, 3A, Faiyaz Centre, Shahrah-e-Faisal Rd, Karachi City</p>
                        <p className="mb-2 text-gray-400">Phone: (+92) 335 272 9843</p>
                        <p className="mb-4 text-gray-400">Email: info@smarttechdevs.com</p>
                        <div className="flex space-x-4">
                            {[FaFacebookF, FaLinkedinIn, FaTwitter].map((Icon, index) => (
                                <a key={index} href="#" className="bg-gray-800 text-[#10ac84] p-2 rounded-full hover:bg-[#10ac84] hover:text-white transition duration-300">
                                    <Icon size={18} />
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="border-t border-gray-800 mt-8 pt-8 text-center">
                    <p className="text-gray-400">&copy; {currentYear} SmartTech Devs. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;