import React, { useState, useEffect } from 'react';
import { Link, useNavigate  } from 'react-router-dom';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const AboutUs = () => {
    const features = [
        {
          icon: "💡",
          title: "One-Stop Solution",
          description: "Get in touch for a fresh perspective on your tech needs. We offer comprehensive solutions for your business."
        },
        {
          icon: "👤",
          title: "Customer Focus",
          description: "We collaborate closely with you to understand and address your unique business challenges and goals."
        },
        {
          icon: "✅",
          title: "Commitment",
          description: "From web development to AI integration, we're committed to delivering top-notch services for your success."
        }
    ];
    
    const coreValues = [
        {
          icon: "🚀",
          title: "Innovation",
          description: "We constantly push the boundaries of technology, embracing new ideas and cutting-edge solutions to solve complex problems.",
          color: "border-blue-500"
        },
        {
          icon: "🤝",
          title: "Client-Centric Approach",
          description: "We put our clients at the heart of everything we do, tailoring our solutions to meet their unique needs and exceed their expectations.",
          color: "border-green-500"
        },
        {
          icon: "🏆",
          title: "Excellence",
          description: "We strive for excellence in every line of code, every design, and every interaction, ensuring top-quality results in all our projects.",
          color: "border-pink-500"
        },
        {
          icon: "🤼",
          title: "Collaboration",
          description: "We believe in the power of teamwork, fostering a collaborative environment that brings out the best in our people and our partners.",
          color: "border-yellow-500"
        }
    ];

    const verticals = [
        { emoji: '🛒', name: 'E-Commerce' },
        { emoji: '🎥', name: 'Multimedia' },
        { emoji: '🏥', name: 'Healthcare' },
        { emoji: '🔧', name: 'Automotive' },
        { emoji: '🏠', name: 'Real Estate' },
        { emoji: '🏨', name: 'Hospitality' },
    ];

    const teamMembers = [
        { name: 'Ahsan Karim', role: 'Sr Web Developer', imageSrc: '/images/team/ahsan.png' },
        { name: 'Fuqaan Ahmed', role: 'Mobile App Developer', imageSrc: '/images/team/furqaan.png' },
        { name: 'Alina Noor', role: 'Sr Web Developer', imageSrc: '/images/team/alina.png' },
        { name: 'Ather Ali', role: 'Full Stack AI Engineer', imageSrc: '/images/team/ather1.png' },
        { name: 'Amna Batool', role: 'Mobile Developer', imageSrc: '/images/team/amna.png' },
        { name: 'Majeed Hussain', role: 'HR Manager', imageSrc: '/images/team/majeed.png' },
        { name: 'Faizan Ahmed', role: 'UX/UI Developer', imageSrc: '/images/team/faizan.png' },
        { name: 'Sania Saleem', role: 'UX/UI Developer', imageSrc: '/images/team/sania.png' },
    ];

    const [selectedMember, setSelectedMember] = useState(null);

    const fadeInUp = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
    };

    const fadeInLeft = {
        hidden: { opacity: 0, x: -50 },
        visible: { opacity: 1, x: 0 }
    };

    const staggerChildren = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2
            }
        }
    };

    const navigate = useNavigate();


    const ScrollTriggeredSection = ({ children, className }) => {
        const controls = useAnimation();
        const [ref, inView] = useInView({
            triggerOnce: true,
            threshold: 0.1,
        });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        return (
            <motion.section
                ref={ref}
                initial="hidden"
                animate={controls}
                variants={staggerChildren}
                className={className}
            >
                {children}
            </motion.section>
        );
    };

    const TeamMember = ({ name, role, imageSrc }) => {
        const [ref, inView] = useInView({
            threshold: 0.2,
            triggerOnce: true
        });

        const controls = useAnimation();

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        return (
            <motion.div 
              ref={ref}
              className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 mb-8"
              initial="hidden"
              animate={controls}
              variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
              }}
            >
              <div className="relative overflow-hidden rounded-lg shadow-lg" style={{ paddingBottom: '100%' }}>
                <img 
                  src={imageSrc} 
                  alt={name} 
                  className="absolute top-0 left-0 w-full h-full object-cover object-center transition-transform duration-300 ease-in-out transform hover:scale-110"
                />
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
                  <h3 className="text-xl font-bold text-white text-center">{name}</h3>
                  <p className="text-sm text-white text-center mt-2">{role}</p>
                </div>
              </div>
            </motion.div>
          );
        };

const navigateToServices = () => {
    navigate('/');  // Navigate to the home page
    setTimeout(() => {  // Wait for navigation to complete
        const servicesSection = document.getElementById('services-section');
        if (servicesSection) {
            servicesSection.scrollIntoView({ behavior: 'smooth' });
        }
    }, 100);  // Adjust this delay if needed
};

    return (
        <div className="overflow-hidden">
            <ScrollTriggeredSection className="relative bg-gray-900 text-white">
                <div 
                    className="absolute inset-0 bg-cover bg-center z-0"
                    style={{
                        backgroundImage: "url('/images/about-us-bg.jpg')",
                        filter: "brightness(33%)",
                        backgroundSize: "cover",
                        backgroundPosition: "center 40%",
                    }}
                ></div>
                <div className="container mx-auto px-4 py-24 relative z-10 max-w-6xl">
                    <motion.h1 
                        className="text-5xl font-bold mb-6"
                        variants={fadeInUp}
                    >
                        About Company
                    </motion.h1>
                    <motion.nav 
                        className="text-sm mb-4"
                        variants={fadeInUp}
                    >
                        <ol className="list-none p-0 inline-flex">
                            <li className="flex items-center">
                                <Link to="/" className="text-gray-400 hover:text-white">Home</Link>
                                <span className="mx-2 text-gray-600">•</span>
                            </li>
                            <li>
                                <span className="text-gray-300">About Us</span>
                            </li>
                        </ol>
                    </motion.nav>
                </div>
            </ScrollTriggeredSection>
          
            <ScrollTriggeredSection className="py-24 bg-white">
                <div className="container mx-auto px-4 max-w-6xl">
                    <div className="flex flex-col md:flex-row items-stretch space-y-12 md:space-y-0 md:space-x-12">
                        <motion.div 
                            className="md:w-1/2 flex-grow"
                            variants={fadeInLeft}
                        >
                            <h2 className="text-xl font-semibold text-[#10ac84] mb-4">Company Overview</h2>
                            <h3 className="text-4xl font-bold text-navy-blue mb-6">
                                Transforming Businesses Through Technology
                            </h3>
                            <motion.p className="text-gray-600 mb-4" variants={fadeInUp}>
                                SmartTech Devs is a leader in the IT industry, known for providing innovative solutions and top-tier technological expertise.
                            </motion.p>
                            <motion.p className="text-gray-600 mb-4" variants={fadeInUp}>
                                With a global presence and deep industry insights, we help businesses grow and embrace digital transformation worldwide.
                            </motion.p>
                            <motion.p className="text-gray-600 mb-4" variants={fadeInUp}>
                                Our experienced team of IT experts and business strategists is dedicated to delivering exceptional results in areas like client relationship management, data analytics, and comprehensive business solutions.
                            </motion.p>
                            <motion.p className="text-gray-600 mb-4" variants={fadeInUp}>
                                From our offices in Pakistan city Gilgit and Karachi we proudly serve a diverse international client base, bringing fresh ideas and transformative technology to businesses everywhere.
                            </motion.p>
                        </motion.div>
                        <motion.div 
                            className="md:w-1/2 flex items-stretch"
                            variants={fadeInUp}
                        >
                            <img 
                                src="/images/about-us-showcase.jpg" 
                                alt="Company About us showcase" 
                                className="w-full h-full object-cover aspect-[16/9] rounded-lg shadow-xl"
                                loading="lazy"
                            />
                        </motion.div>
                    </div>
                </div>
            </ScrollTriggeredSection>

            <ScrollTriggeredSection className="py-24 bg-gray-100">
                <div className="container mx-auto px-4 max-w-6xl">
                    <motion.h2 
                        className="text-xl font-semibold text-[#10ac84] text-center mb-4"
                        variants={fadeInUp}
                    >
                        Why Us
                    </motion.h2>
                    <motion.h3 
                        className="text-5xl font-bold text-navy-blue text-center mb-12"
                        variants={fadeInUp}
                    >
                        We match your unique business needs with<br />our deep understanding.
                    </motion.h3>
                    <motion.div 
                        className="grid grid-cols-1 md:grid-cols-3 gap-8"
                        variants={staggerChildren}
                    >
                        {features.map((feature, index) => (
                            <motion.div 
                                key={index}
                                className="bg-white p-6 rounded-lg shadow-lg"
                                variants={fadeInUp}
                                whileHover={{ y: -2 }}
                                transition={{ duration: 0.1 }}
                            >
                                <div className="text-4xl text-[#10ac84] mb-4">{feature.icon}</div>
                                <h4 className="text-xl font-semibold text-navy-blue mb-2">{feature.title}</h4>
                                <p className="text-gray-600">{feature.description}</p>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </ScrollTriggeredSection>

            <ScrollTriggeredSection className="py-24 bg-gray-50">
                <div className="container mx-auto px-4 max-w-6xl">
                    <motion.h2 
                        className="text-5xl font-bold text-center text-navy-blue mb-4"
                        variants={fadeInUp}
                    >
                        Our Guiding Principles
                    </motion.h2>
                    <motion.p 
                        className="text-center text-gray-600 mb-12"
                        variants={fadeInUp}
                    >
                        For over a decade, we've been crafting innovative software solutions guided by these core values.
                    </motion.p>
                    <motion.div 
                        className="grid grid-cols-1 md:grid-cols-2 gap-8"
                        variants={staggerChildren}
                    >
                        {coreValues.map((value, index) => (
                            <motion.div 
                                key={index}
                                className={`bg-white p-8 rounded-lg shadow-lg flex flex-col h-full ${value.color} border-b-4`}
                                variants={fadeInUp}
                                whileHover={{scale: 1.05 }}
                            >
                                <div className="flex items-center mb-4">
                                    <span className="text-4xl mr-4">{value.icon}</span>
                                    <h3 className="text-2xl font-semibold text-navy-blue">{value.title}</h3>
                                </div>
                                <p className="text-gray-600 flex-grow text-lg">{value.description}</p>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </ScrollTriggeredSection>

            <ScrollTriggeredSection className="py-24 bg-white">
                <div className="container mx-auto px-4 max-w-6xl">
                    <div className="flex flex-col md:flex-row justify-between items-start space-y-12 md:space-y-0 md:space-x-12">
                        <motion.div
                            className="md:w-1/2"
                            variants={fadeInLeft}
                        >
                            <h2 className="text-xl font-semibold text-[#10ac84] mb-2">Our Verticals</h2>
                            <h3 className="text-5xl font-bold text-navy-blue mb-4">Industries we work in</h3>
                            <p className="text-gray-600 mb-8 max-w-xl">
                                Starting a business should be simple. We believe in the power of business startup to change lives and change the world.
                            </p>
                            <motion.button
                            className="bg-[#10ac84] text-white py-2 px-6 rounded-md"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            transition={{ duration: 0.5 }}
                            onClick={navigateToServices}
                        >
                            Explore Our Services
                        </motion.button>
                        </motion.div>
                        <motion.div
                            className="md:w-1/2 grid grid-cols-2 md:grid-cols-3 gap-8"
                            variants={staggerChildren}
                        >
                            {verticals.map((vertical, index) => (
                                <motion.div
                                    key={index}
                                    className="flex flex-col items-center"
                                    variants={fadeInUp}
                                >
                                    <motion.div
                                        className="w-16 h-16 rounded-full border-2 border-[#10ac84] flex items-center justify-center text-3xl mb-2"
                                        whileHover={{
                                            scale: 1.1,
                                            backgroundColor: '#10ac84',
                                            color: 'white',
                                            transition: { duration: 0.3 }
                                        }}
                                    >
                                        <span>{vertical.emoji}</span>
                                    </motion.div>
                                    <p className="text-navy-blue font-semibold text-center">{vertical.name}</p>
                                </motion.div>
                            ))}
                        </motion.div>
                    </div>
                </div>
            </ScrollTriggeredSection>

            <ScrollTriggeredSection className="py-24 bg-gray-100">
                <div className="container mx-auto px-4 max-w-6xl">
                    <motion.h2 
                        className="text-5xl font-bold text-center text-gray-800 mb-4"
                        variants={{
                            hidden: { opacity: 0, y: -30 },
                            visible: { 
                              opacity: 1, 
                              y: 0,
                              transition: {
                                duration: 1,
                                delay: 0.3,
                                ease: "easeOut"
                              }
                            }
                          }}
                    >
                        Our Team
                    </motion.h2>
                    <motion.p 
                        className="text-center text-gray-600 mb-12"
                        variants={{
                            hidden: { opacity: 0, y: -30 },
                            visible: { 
                              opacity: 1, 
                              y: 0,
                              transition: {
                                duration: 1,
                                delay: 0.4,
                                ease: "easeOut"
                              }
                            }
                          }}
                    >
                        Meet the experts behind our success
                    </motion.p>
                    <motion.p  variants={{
                    hidden: { opacity: 0, y: -30 },
                        visible: { 
                            opacity: 1, 
                            y: 0,
                            transition: {
                            duration: 1,
                            delay: 0.5,
                            ease: "easeOut"
                            }
                        }
                    }}>
                    <div  className="flex flex-wrap -mx-2">
                        {teamMembers.map((member, index) => (
                            <TeamMember key={index} {...member} />
                        ))}
                    </div>
                    </motion.p>
                </div>
        <AnimatePresence>
                    {selectedMember && (
                        <motion.div 
                            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            onClick={() => setSelectedMember(null)}
                        >
                            <motion.div 
                                className="bg-white rounded-lg p-8 max-w-2xl w-full mx-4"
                                initial={{ y: 50, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: 50, opacity: 0 }}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <div className="flex items-start mb-6">
                                    <img 
                                        src={selectedMember.imageSrc} 
                                        alt={selectedMember.name} 
                                        className="w-24 h-24 rounded-full object-cover mr-6"
                                    />
                                    <div>
                                        <h3 className="text-2xl font-bold text-gray-800">{selectedMember.name}</h3>
                                        <p className="text-lg text-gray-600">{selectedMember.role}</p>
                                    </div>
                                </div>
                                <p className="text-gray-700">{selectedMember.bio}</p>
                                <button 
                                    className="mt-6 bg-gray-800 text-white px-4 py-2 rounded hover:bg-gray-700 transition-colors"
                                    onClick={() => setSelectedMember(null)}
                                >
                                    Close
                                </button>
                            </motion.div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </ScrollTriggeredSection>
        </div>
    );
};

export default AboutUs;