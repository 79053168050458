import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const ArticleCard = ({ article, index }) => {
    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true
    });

    const controls = useAnimation();

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    const cardVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
                delay: index * 0.1
            }
        }
    };

    return (
        <motion.div
            ref={ref}
            variants={cardVariants}
            initial="hidden"
            animate={controls}
            whileHover={{ scale: 1.05 }}
            className="relative overflow-hidden rounded-lg shadow-lg h-80 group"
        >
            <Link to={article.link} className="block h-full">
                <img src={article.image} alt={article.title} className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110" />
                <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-70 group-hover:opacity-90 transition-opacity duration-300"></div>
                <div className="absolute bottom-0 left-0 right-0 p-6 transform translate-y-2 group-hover:translate-y-0 transition-transform duration-300">
                    <h3 className="text-xl font-bold text-white mb-2">{article.title}</h3>
                    <p className="text-sm text-gray-300 mb-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">{article.excerpt}</p>
                    <span className="text-[#10ac84] font-semibold text-sm">Read More →</span>
                </div>
            </Link>
        </motion.div>
    );
};

const Article = () => {
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true
    });

    const controls = useAnimation();

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    const sectionVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2
            }
        }
    };

    const titleVariants = {
        hidden: { opacity: 0, y: -30 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.8,
                ease: "easeOut"
            }
        }
    };

    const articles = [
        {
            title: "Finding the Right App Developers for Your Project: A Guide for Businesses",
            image: "/images/app-development.jpg",
            excerpt: "Learn how to choose the perfect app development team for your next project.",
            link: "https://www.linkedin.com/pulse/how-find-right-mobile-app-development-partner-your-startup-singh-jyjhc/"
        },
        {
            title: "Elevate Your Web Development Game: The 7 Best Frameworks in 2024",
            image: "/images/web-development.jpg",
            excerpt: "Discover the top web development frameworks that are shaping the industry in 2024.",
            link: "https://www.linkedin.com/pulse/top-web-development-frameworks-success-2024-bootesnull-tubcc/"
        },
        {
            title: "Unveiling the Potential: Exploring Generative AI in LLM Development Projects",
            image: "/images/generative-ai-process.jpg",
            excerpt: "How to Build LLM Applications with LangChain Tutorial",
            link: "https://www.datacamp.com/tutorial/how-to-build-llm-applications-with-langchain"
        }
    ];

    return (
        <motion.section 
            ref={ref}
            variants={sectionVariants}
            initial="hidden"
            animate={controls}
            className="py-24 bg-gray-100 overflow-hidden"
        >
            <div className="container mx-auto px-4 max-w-6xl">
                <motion.p
                    variants={titleVariants}
                    className="text-center text-[#10ac84] mb-2 text-lg font-semibold"
                >
                    Latest Insights
                </motion.p>
                <motion.h2
                    variants={titleVariants}
                    className="text-5xl font-bold text-center text-gray-800 mb-6"
                >
                    Explore Our Tech Articles
                </motion.h2>
                <motion.p
                    variants={titleVariants}
                    className="text-xl text-center text-gray-600 mb-16 max-w-2xl mx-auto"
                >
                    Stay updated with the latest trends and insights in the world of technology and software development.
                </motion.p>
                <motion.div 
                    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12"
                    variants={sectionVariants}
                >
                    {articles.map((article, index) => (
                        <ArticleCard key={index} article={article} index={index} />
                    ))}
                </motion.div>
                <motion.div
                    className="text-center mt-16"
                    variants={titleVariants}
                >
                    <Link
                        to=""
                        className="inline-block bg-[#10ac84] text-white py-3 px-8 rounded-lg text-lg font-semibold hover:bg-[#0d8b6a] transition duration-300"
                    >
                        View All Articles
                    </Link>
                </motion.div>
            </div>
        </motion.section>
    );
};

export default Article;