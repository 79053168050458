import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const ProjectCard = ({ project, index }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });
    const controls = useAnimation();

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    const cardVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
                delay: index * 0.1,
            },
        },
    };

    return (
        <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={cardVariants}
            className="bg-white rounded-lg shadow-lg overflow-hidden"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
        >
            <Link to={project.link} className="block">
                <motion.img
                    src={project.image}
                    alt={project.title}
                    className="w-full h-48 object-cover"
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.3 }}
                />
                <div className="p-6">
                    <h3 className="text-2xl font-semibold text-gray-800 mb-2">{project.title}</h3>
                    <p className="text-gray-600 mb-4">{project.description}</p>
                    <div className="flex flex-wrap">
                        {project.tags.map((tag, tagIndex) => (
                            <motion.span
                                key={tagIndex}
                                className="bg-[#10ac84] text-white px-2 py-1 rounded text-sm mr-2 mb-2"
                                whileHover={{ scale: 1.1 }}
                                transition={{ duration: 0.2 }}
                            >
                                {tag}
                            </motion.span>
                        ))}
                    </div>
                </div>
            </Link>
        </motion.div>
    );
};

const Showcase = () => {
    const [heroRef, heroInView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });
    const [projectsRef, projectsInView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });
    const [ctaRef, ctaInView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });
    const heroControls = useAnimation();
    const projectsControls = useAnimation();
    const ctaControls = useAnimation();

    useEffect(() => {
        if (heroInView) heroControls.start('visible');
        if (projectsInView) projectsControls.start('visible');
        if (ctaInView) ctaControls.start('visible');
    }, [heroInView, projectsInView, ctaInView, heroControls, projectsControls, ctaControls]);

    const sectionVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                when: 'beforeChildren',
                staggerChildren: 0.1,
            },
        },
    };

    const projects = [
        {
            title: "SubGPT",
            description: "Transform your customer experience with our AI-powered customer engagement platform. Personalize every interaction.",
            image: "/images/projects/subgpt.png",
            tags: ["JavaScript", "Node.js", "MongoDB"],
            link: "https://subgpt.ai/"
        },
        {
            title: "Mindpal",
            description: "Imagine a world where your most time-consuming tasks are handled effortlessly by a team of AI agents and multi-agent workflows. That's the power of MindPal.",
            image: "/images/projects/mindpal.png",
            tags: ["Python", "React", "NLP"],
            link: "/projects/ai-chatbot"
        },
        {
            title: "Reviewradar",
            description: "Chat with software reviews to understand what users like, what bothers them, and what they're looking for in a product.",
            image: "/images/projects/reviewradar.png",
            tags: ["React", "Django", "MongoDB"],
            link: "/projects/mobile-banking-app"
        },
        {
            title: "StoryArtAI",
            description: "Bring Your Stories to Life with AI Magic",
            image: "/images/projects/storyai.png",
            tags: ["React", "Python", "RAG"],
            link: "/projects/smart-home-iot"
        },
        {
            title: "AIcheckerai",
            description: "Optimize Your Alt Text for Better SEO!",
            image: "/images/projects/aicheckerai.png",
            tags: ["React", "Vue.js", "RAG"],
            link: "/projects/data-visualization"
        },
    ];

    return (
        <div className="overflow-hidden">
            <motion.section
                ref={heroRef}
                initial="hidden"
                animate={heroControls}
                variants={sectionVariants}
                className="relative bg-gray-900 text-white py-24"
            >
                <div
                    className="absolute inset-0 bg-cover bg-center z-0"
                    style={{
                        backgroundImage: "url('/images/showcase-bg.jpg')",
                        filter: "brightness(33%)",
                        backgroundSize: "cover",
                        backgroundPosition: "center 70%",
                    }}
                ></div>
                <div className="container mx-auto px-4 max-w-6xl relative z-10">
                    <motion.h1
                        className="text-5xl font-bold mb-6"
                        variants={{
                            hidden: { opacity: 0, y: -30 },
                            visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
                        }}
                    >
                        Transforming Ideas into Digital Reality
                    </motion.h1>
                    <motion.p
                        className="text-xl mb-8 max-w-2xl"
                        variants={{
                            hidden: { opacity: 0, y: 30 },
                            visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
                        }}
                    >
                        Explore our portfolio of innovative solutions that push the boundaries of technology and drive business success.
                    </motion.p>
                    <motion.nav
                        className="text-sm mb-4"
                        variants={{
                            hidden: { opacity: 0 },
                            visible: { opacity: 1, transition: { duration: 0.5 } },
                        }}
                    >
                        <ol className="list-none p-0 inline-flex">
                            <li className="flex items-center">
                                <Link to="/" className="text-gray-400 hover:text-white transition duration-300">Home</Link>
                                <span className="mx-2 text-gray-600">•</span>
                            </li>
                            <li>
                                <span className="text-gray-300">Showcase</span>
                            </li>
                        </ol>
                    </motion.nav>
                </div>
            </motion.section>

            <motion.section
                ref={projectsRef}
                initial="hidden"
                animate={projectsControls}
                variants={sectionVariants}
                className="py-24 bg-white"
            >
                <div className="container mx-auto px-4 max-w-6xl">
                    <motion.h2
                        className="text-5xl font-bold text-center text-gray-800 mb-6"
                        variants={{
                            hidden: { opacity: 0, y: -30 },
                            visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
                        }}
                    >
                        Our Cutting-Edge Projects
                    </motion.h2>
                    <motion.p
                        className="text-xl text-center text-gray-600 mb-16 max-w-2xl mx-auto"
                        variants={{
                            hidden: { opacity: 0, y: 30 },
                            visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
                        }}
                    >
                        From AI-driven solutions to immersive user experiences, our projects showcase the pinnacle of technological innovation and creative problem-solving.
                    </motion.p>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {projects.map((project, index) => (
                            <ProjectCard key={index} project={project} index={index} />
                        ))}
                    </div>
                </div>
            </motion.section>

            <motion.section
                ref={ctaRef}
                initial="hidden"
                animate={ctaControls}
                variants={sectionVariants}
                className="py-24 bg-[#10ac84] relative overflow-hidden"
            >
                <div className="absolute top-0 left-0 w-full h-full">
                    <motion.svg
                        className="absolute top-0 left-0 h-full w-full"
                        preserveAspectRatio="none"
                        viewBox="0 0 100 100"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1, delay: 0.5 }}
                    >
                        <motion.path
                            d="M0 0 L50 100 L100 0 Z"
                            fill="rgba(255,255,255,0.1)"
                            initial={{ pathLength: 0 }}
                            animate={{ pathLength: 1 }}
                            transition={{ duration: 1.5, ease: "easeInOut" }}
                        />
                    </motion.svg>
                    <motion.svg
                        className="absolute bottom-0 right-0 h-full w-full"
                        preserveAspectRatio="none"
                        viewBox="0 0 100 100"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1, delay: 0.5 }}
                    >
                        <motion.path
                            d="M0 100 L50 0 L100 100 Z"
                            fill="rgba(255,255,255,0.05)"
                            initial={{ pathLength: 0 }}
                            animate={{ pathLength: 1 }}
                            transition={{ duration: 1.5, ease: "easeInOut" }}
                        />
                    </motion.svg>
                </div>
                <div className="container mx-auto px-4 max-w-6xl text-center relative z-10">
                    <motion.h2
                        className="text-5xl font-bold text-white mb-6"
                        variants={{
                            hidden: { opacity: 0, y: -30 },
                            visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
                        }}
                    >
                        Ready to Bring Your Ideas to Life?
                    </motion.h2>
                    <motion.p
                        className="text-xl text-white text-opacity-90 mb-12 max-w-2xl mx-auto"
                        variants={{
                            hidden: { opacity: 0, y: 30 },
                            visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
                        }}
                    >
                        Let's collaborate to create innovative solutions that drive your business forward. Our team of experts is ready to turn your vision into reality.
                    </motion.p>
                    <motion.div
                        variants={{
                            hidden: { opacity: 0, scale: 0.8 },
                            visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
                        }}
                    >
                        <Link
                            to="/contact"
                            className="inline-block bg-white text-[#10ac84] py-3 px-8 rounded-full text-lg font-semibold hover:bg-gray-100 transition duration-300"
                        >
                            Start Your Project
                        </Link>
                    </motion.div>
                </div>
            </motion.section>
        </div>
    );
};

export default Showcase;