import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const ContactUs = () => {
    const [formStatus, setFormStatus] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormStatus(`Thank you for your message. We'll get back to you soon!`);
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1,
                delayChildren: 0.3
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { 
            opacity: 1, 
            y: 0,
            transition: {
                type: "spring",
                stiffness: 100
            }
        }
    };

    const ScrollTriggeredSection = ({ children, className }) => {
        const controls = useAnimation();
        const [ref, inView] = useInView({
            triggerOnce: true,
            threshold: 0.1,
        });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        return (
            <motion.section
                ref={ref}
                initial="hidden"
                animate={controls}
                variants={containerVariants}
                className={className}
            >
                {children}
            </motion.section>
        );
    };

    return (
        <div className="overflow-hidden">
            <ScrollTriggeredSection className="relative bg-gray-900 text-white py-24">
                <div 
                    className="absolute inset-0 bg-cover bg-center z-0"
                    style={{
                        backgroundImage: "url('/images/contact-us-bg.jpg')",
                        filter: "brightness(33%)",
                        backgroundSize: "cover",
                        backgroundPosition: "center 40%",
                    }}
                ></div>
                <div className="absolute inset-0 bg-blue-500 z-10 mix-blend-overlay opacity-35"></div>
                <div className="container mx-auto px-4 max-w-6xl relative z-20">
                    <motion.h1 
                        className="text-5xl font-bold mb-6"
                        variants={itemVariants}
                    >
                        Contact Us
                    </motion.h1>
                    <motion.nav 
                        className="text-sm mb-4"
                        variants={itemVariants}
                    >
                        <ol className="list-none p-0 inline-flex">
                            <li className="flex items-center">
                                <Link to="/" className="text-gray-400 hover:text-white">Home</Link>
                                <span className="mx-2 text-gray-600">•</span>
                            </li>
                            <li>
                                <span className="text-gray-300">Contact Us</span>
                            </li>
                        </ol>
                    </motion.nav>
                </div>
            </ScrollTriggeredSection>

            <ScrollTriggeredSection className="py-24 bg-gradient-to-br from-[#10ac84] to-[#0d8b6a] text-white relative overflow-hidden">
                <div className="absolute inset-0 opacity-20">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1000 1000" preserveAspectRatio="xMidYMid slice">
                        <defs>
                            <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="100%">
                                <stop offset="0%" stopColor="#ffffff" stopOpacity="0.3" />
                                <stop offset="100%" stopColor="#ffffff" stopOpacity="0.1" />
                            </linearGradient>
                        </defs>
                        <rect width="1000" height="1000" fill="url(#grad)" />
                        <path d="M0,250 Q250,0 500,250 T1000,250" fill="none" stroke="white" strokeWidth="3" opacity="0.3" />
                        <path d="M0,500 Q250,250 500,500 T1000,500" fill="none" stroke="white" strokeWidth="3" opacity="0.3" />
                        <path d="M0,750 Q250,500 500,750 T1000,750" fill="none" stroke="white" strokeWidth="3" opacity="0.3" />
                    </svg>
                </div>

                <div className="container mx-auto px-4 max-w-6xl relative z-10">
                    <div className="flex flex-wrap -mx-4">
                        <motion.div 
                            className="w-full md:w-1/2 px-4 mb-8 md:mb-0"
                            variants={containerVariants}
                        >
                            <motion.h2 variants={itemVariants} className="text-3xl font-bold mb-4">Need help?</motion.h2>
                            <motion.h3 variants={itemVariants} className="text-4xl font-bold mb-8">Let's discuss your case and how we can help you</motion.h3>
                            
                            {['Karachi, Pakistan', 'Gilgit, Pakistan'].map((location, index) => (
                                <motion.div key={index} variants={itemVariants} className="mb-6">
                                    <h4 className="font-bold mb-2">{location}</h4>
                                    <p>{['(+92) 335 272 9843', '(+92) 04 587 6933',][index]}</p>
                                </motion.div>
                            ))}
                            
                            <motion.div variants={itemVariants} className="mb-6">
                                <h4 className="font-bold mb-2">Email us</h4>
                                <p>info@smarttechdevs.com</p>
                            </motion.div>
                            
                            {['Gilgit', 'Karachi'].map((country, index) => (
                                <motion.div key={index} variants={itemVariants} className="mb-2">
                                    <h4 className="font-bold mb-2">{country}</h4>
                                    <p>{[
                                        'Naveed Shaheed Road, Zulfiqarabad Gilgit',
                                        '4th Floor, 3A, Faiyaz Centre, Shahrah-e-Faisal Rd, Karachi City, Sindh, Pakistan'
                                    ][index]}</p>
                                </motion.div>
                            ))}
                        </motion.div>
                        <motion.div 
                            className="w-full md:w-1/2 px-4 flex"
                            initial={{ opacity: 0, x: 50 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.8 }}
                        >
                            <div className="bg-white rounded-lg shadow-2xl p-8 w-full flex flex-col relative overflow-hidden">
                                <div className="absolute inset-0 opacity-5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
                                        <defs>
                                            <pattern id="subtlePattern" patternUnits="userSpaceOnUse" width="100" height="100">
                                                <circle cx="50" cy="50" r="1" fill="#10ac84" />
                                                <circle cx="0" cy="0" r="1" fill="#10ac84" />
                                                <circle cx="100" cy="100" r="1" fill="#10ac84" />
                                            </pattern>
                                        </defs>
                                        <rect width="100%" height="100%" fill="url(#subtlePattern)" />
                                    </svg>
                                </div>
                                
                                <h2 className="text-2xl font-bold mb-6 text-gray-800 relative z-10">Drop a Message</h2>
                                <motion.form 
                                    onSubmit={handleSubmit} 
                                    className="space-y-6 flex-grow flex flex-col relative z-10"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: 0.5, duration: 0.5 }}
                                >
                                    <motion.div whileHover={{ scale: 1.02 }} transition={{ type: "spring", stiffness: 400, damping: 10 }}>
                                        <select className="w-full p-3 rounded-md text-gray-800 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#10ac84] transition duration-300 bg-white bg-opacity-70">
                                            <option>How can we help you?</option>
                                            {/* Add more options here */}
                                        </select>
                                    </motion.div>
                                    {['Your Name', 'Your Phone Number', 'Your Email'].map((placeholder, index) => (
                                        <motion.div key={index} whileHover={{ scale: 1.02 }} transition={{ type: "spring", stiffness: 400, damping: 10 }}>
                                            <input 
                                                type={index === 1 ? "tel" : index === 2 ? "email" : "text"}
                                                placeholder={placeholder}
                                                className="w-full p-3 rounded-md text-gray-800 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#10ac84] transition duration-300 bg-white bg-opacity-70" 
                                                required
                                            />
                                        </motion.div>
                                    ))}
                                    <motion.div className="flex-grow" whileHover={{ scale: 1.02 }} transition={{ type: "spring", stiffness: 400, damping: 10 }}>
                                        <textarea 
                                            placeholder="Message" 
                                            className="w-full h-32 p-3 rounded-md text-gray-800 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#10ac84] transition duration-300 resize-none bg-white bg-opacity-70"
                                            required
                                        ></textarea>
                                    </motion.div>
                           
                                    <motion.button 
                                        type="submit" 
                                        className="w-full bg-[#10ac84] text-white py-3 px-6 rounded-md font-semibold hover:bg-[#0d8b6a] transition duration-300"
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                    >
                                        Submit
                                    </motion.button>
                                </motion.form>
                                {formStatus && (
                                    <motion.div 
                                        className="mt-6 text-center text-green-600 font-semibold relative z-10"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.5 }}
                                    >
                                        {formStatus}
                                    </motion.div>
                                )}
                            </div>
                        </motion.div>
                    </div>
                </div>
            </ScrollTriggeredSection>
        </div>
    );
};

export default ContactUs;