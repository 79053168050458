import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const UIUXDesign = () => {
  const designServices = [
    {
      icon: "/images/user-research-icon.svg",
      title: "User Research for UI/UX Design",
      description: "In today's digital landscape, understanding your users' needs, behaviors, and pain points is crucial for any company looking to succeed. The key to achieving business goals."
    },
    {
      icon: "/images/user-flows-icon.svg",
      title: "User Flows & Wireframes",
      description: "With our UI/UX Design Services, we craft user-friendly flows and wireframes that map every user action in your app or website. This purposeful approach meets user needs and boosts brand growth."
    },
    {
      icon: "/images/mockups-icon.svg",
      title: "Mockups-UI/UX",
      description: "At our company, we pay attention to every detail, from color scheme to typography, to create a visually appealing and cohesive design. We add visual elements to wireframes for a static representation, enabling necessary changes before UI/UX design and development services."
    },
    {
      icon: "/images/prototype-icon.svg",
      title: "Design and Prototype",
      description: "Enhance your user interface with elevated UI/UX services. We provide a unique style guide for your project, ensuring a visually attractive and cohesive final product. Our UI/UX designers rigorously test your interface for accessibility and usability across all browsers and devices."
    }
  ];

  const ScrollTriggeredSection = ({ children, className }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.1,
    });

    React.useEffect(() => {
      if (inView) {
        controls.start('visible');
      }
    }, [controls, inView]);

    return (
      <motion.section
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          visible: {
            transition: {
              staggerChildren: 0.3,
            },
          },
        }}
        className={className}
      >
        {children}
      </motion.section>
    );
  };

  const serviceVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  return (
    <div className="overflow-hidden">
      <ScrollTriggeredSection className="relative bg-gray-900 text-white py-24">
        <div 
          className="absolute inset-0 bg-cover bg-center z-0"
          style={{
            backgroundImage: "url('/images/uiux-design-bg.jpg')",
            filter: "brightness(33%)",
            backgroundSize: "cover",
            backgroundPosition: "center 30%",
          }}
        ></div>
        <div className="absolute inset-0 bg-blue-500 z-10 mix-blend-overlay opacity-35"></div>
        <div className="container mx-auto px-4 max-w-6xl relative z-20">
          <motion.h1 
            className="text-5xl font-bold mb-6"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            UI/UX Design
          </motion.h1>
          <motion.nav 
            className="text-sm mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            <ol className="list-none p-0 inline-flex">
              <li className="flex items-center">
                <Link  to="/" className="text-gray-400 hover:text-white">Home</Link>
                <span className="mx-2 text-gray-600">•</span>
              </li>
              <li>
                <span className="text-gray-300">UI/UX Design</span>
              </li>
            </ol>
          </motion.nav>
        </div>
      </ScrollTriggeredSection>
      
      <ScrollTriggeredSection className="py-24 bg-white">
        <div className="container mx-auto px-4 max-w-6xl">
          <div className="flex flex-col md:flex-row items-stretch space-y-12 md:space-y-0 md:space-x-12">
            <motion.div 
              className="md:w-3/5"
              variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
              }}
            >
              <motion.h2 
                className="text-2xl font-semibold text-[#10ac84] mb-4"
                variants={{
                  hidden: { opacity: 0, y: -20 },
                  visible: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.2 } }
                }}
              >
                UI/UX Design Excellence
              </motion.h2>
              <motion.h3 
                className="text-4xl font-bold text-gray-800 mb-6"
                variants={{
                  hidden: { opacity: 0, y: -20 },
                  visible: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.3 } }
                }}
              >
                Crafting Digital Experiences That Inspire
              </motion.h3>
              <motion.p 
                className="text-gray-600 mb-4"
                variants={{
                  hidden: { opacity: 0, y: 20 },
                  visible: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.4 } }
                }}
              >
                At SmartTech Devs, we believe that great design is the cornerstone of exceptional digital experiences. Our UI/UX design team combines creativity with user-centered methodologies to create interfaces that are not just visually stunning, but also intuitive and engaging.
              </motion.p>
              <motion.p 
                className="text-gray-600 mb-6"
                variants={{
                  hidden: { opacity: 0, y: 20 },
                  visible: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.5 } }
                }}
              >
                From thorough user research to pixel-perfect prototypes, we ensure that every aspect of your digital product is thoughtfully designed to meet user needs and business objectives. Our designs don't just look good – they perform, converting visitors into loyal users and driving your business forward.
              </motion.p>
              <motion.button 
                className="bg-[#10ac84] text-white py-3 px-8 rounded-lg text-lg font-semibold hover:bg-[#0d8b6a] transition duration-300"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link to="/contact">Start Your Design Journey</Link>
              </motion.button>
            </motion.div>

            <motion.div 
              className="md:w-3/5 flex items-center"
              variants={{
                hidden: { opacity: 0, x: 50 },
                visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
              }}
            >
              <motion.img
                src="/images/uiux-design-showcase1.jpg"
                alt="UI/UX Design Showcase"
                className="rounded-lg shadow-xl w-full h-full object-cover"
                animate={{ y: [0, -10, 0] }}
                transition={{
                  duration: 4,
                  repeat: Infinity,
                  repeatType: "reverse",
                  ease: "easeInOut"
                }}
              />
            </motion.div>
          </div>
        </div>
      </ScrollTriggeredSection>

      <ScrollTriggeredSection className="py-24 bg-gray-100">
      <div className="container mx-auto px-4 max-w-6xl">
    <motion.h2 
      className="text-5xl font-bold text-center text-gray-800 mb-12"
      initial={{ opacity: 0, y: -30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      Design Something That Reflects Your Vision
    </motion.h2>
    
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      {[
        {
          icon: "🔍",
          title: "User Research for UI/UX Design",
          description: "In today's digital landscape, understanding your users' needs, behaviors, and pain points is crucial for any company looking to succeed. The key to achieving business goals."
        },
        {
          icon: "🔀",
          title: "User Flows & Wireframes",
          description: "With our UI/UX Design Services, we craft user-friendly flows and wireframes that map every user action in your app or website. This purposeful approach meets user needs and boosts brand growth."
        },
        {
          icon: "🎨",
          title: "Mockups-UI/UX",
          description: "At SmartTech Devs, we pay attention to every detail, from color scheme to typography, to create a visually appealing and cohesive design. We add visual elements to wireframes for a static representation, enabling necessary changes before UI/UX design and development services."
        },
        {
          icon: "📱",
          title: "Design and Prototype",
          description: "Enhance your user interface with elevated UI/UX services. We provide a unique style guide for your project, ensuring a visually attractive and cohesive final product. Our UI/UX designers rigorously test your interface for accessibility and usability across all browsers and devices."
        }
      ].map((service, index) => (
        <motion.div
        key={index}
        className="bg-white p-8 rounded-lg shadow-lg flex flex-col h-full transform transition-all duration-300 hover:shadow-2xl hover:-translate-y-2"
        variants={serviceVariants}
      >
        <div className="flex items-center mb-6">
          <span className="text-4xl mr-4" role="img" aria-label={service.title}>{service.icon}</span>
          <h3 className="text-2xl font-semibold text-gray-800">{service.title}</h3>
        </div>
        <p className="text-gray-600 flex-grow text-lg leading-relaxed">{service.description}</p>
        <div className="mt-6 pt-4 border-t-2 border-[#10ac84]">
        </div>
      </motion.div>
    ))}
  </div>
</div>
      </ScrollTriggeredSection>

      <ScrollTriggeredSection className="relative py-24 bg-gray-900 text-white overflow-hidden">
        <div 
          className="absolute inset-0 bg-cover bg-center z-0"
          style={{
            backgroundImage: "url('/images/uiux-design-cta-bg.jpg')",
            filter: "brightness(35%)",
            backgroundSize: "cover",
            backgroundPosition: "center 30%",
          }}
        ></div>
        <div className="absolute inset-0 bg-blue-500 z-10 mix-blend-overlay opacity-35"></div>
        
        <div className="container mx-auto px-4 max-w-6xl relative z-20">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0">
            <motion.div 
              className="md:w-2/3"
              variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
              }}
            >
              <h2 className="text-4xl font-bold mb-4">Ready to elevate your digital experience?</h2>
              <p className="text-xl max-w-2xl">
                Let's create interfaces that captivate and convert. Our team is ready to bring your vision to life.
              </p>
            </motion.div>
            <motion.div
              variants={{
                hidden: { opacity: 0, x: 50 },
                visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
              }}
            >
              <Link 
                to="/contact" 
                className="bg-[#10ac84] text-white py-3 px-8 rounded-lg text-lg font-semibold hover:bg-[#0d8b6a] transition duration-300 inline-block"
              >
                Start Your Design Project
              </Link>
            </motion.div>
          </div>
        </div>
      </ScrollTriggeredSection>
    </div>
  );
};

export default UIUXDesign;