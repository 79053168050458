import React from 'react';
import { useSpring, animated, config } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';

const ServiceCard = ({ service, index }) => {
    const [isHovered, setIsHovered] = React.useState(false);
    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true
    });

    const controls = useAnimation();

    React.useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    const cardVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
                delay: index * 0.1
            }
        }
    };

    const hoverAnimation = useSpring({
        transform: isHovered ? 'scale(1.02)' : 'scale(1)',
        boxShadow: isHovered
            ? '0 15px 30px rgba(0, 0, 0, 0.15)'
            : '0 5px 15px rgba(0, 0, 0, 0.05)',
        backgroundColor: isHovered ? '#10ac84' : '#fff',
        config: config.gentle,
    });

    const iconAnimation = useSpring({
        transform: isHovered ? 'rotate(360deg)' : 'rotate(0deg)',
        config: { tension: 100, friction: 20 },
    });

    const textColor = isHovered ? 'text-white' : 'text-gray-700';

    return (
        <motion.div
            ref={ref}
            variants={cardVariants}
            initial="hidden"
            animate={controls}
        >
            <Link
                to={`/services/${service.slug}`}
                className="block h-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <animated.div
                    style={hoverAnimation}
                    className="p-8 rounded-xl transition-transform duration-300 relative h-full flex flex-col"
                >
                    <animated.div
                        className={`w-16 h-16 flex items-center justify-center rounded-full mb-6 transition-colors duration-300`}
                        style={{
                            ...iconAnimation,
                            backgroundColor: isHovered ? 'white' : '#10ac84',
                            color: isHovered ? '#10ac84' : 'white',
                        }}
                    >
                        {service.icon}
                    </animated.div>
                    <h3 className={`text-2xl font-bold mb-4 ${isHovered ? 'text-white' : 'text-gray-800'}`}>
                        {service.title}
                    </h3>
                    <p className={`${textColor} flex-grow transition-colors duration-300 text-lg`}>
                        {service.description}
                    </p>
                    {isHovered && (
                        <div className="absolute right-6 bottom-6 text-white transition-opacity duration-300">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                            </svg>
                        </div>
                    )}
                </animated.div>
            </Link>
        </motion.div>
    );
};

const ServicesSection = () => {
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true
    });

    const controls = useAnimation();

    React.useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    const sectionVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2
            }
        }
    };

    const titleVariants = {
        hidden: { opacity: 0, y: -30 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.8,
                ease: "easeOut"
            }
        }
    };

    const services = [
        {
            icon: <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /></svg>,
            title: "Web Development",
            description: "Build high-performing, dynamic websites that drive business growth with our web development services.",
            slug: "web-development"
        },
        {
            icon: <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" /></svg>,
            title: "App Development",
            description: "Bring your ideas to life with expert mobile app development for iOS, Android, and cross-platform.",
            slug: "app-development"
        },
        {
            icon: <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" /></svg>,
            title: "Generative AI",
            description: "Leverage the power of AI to create innovative, efficient solutions for your business.",
            slug: "generative-ai"
        },
        {
            icon: <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" /></svg>,
            title: "UI/UX Design",
            description: "Captivate and engage your audience with stunning, intuitive UI/UX designs.",
            slug: "ui-ux-design"
        },
    ];

    return (
        <motion.section
            ref={ref}
            variants={sectionVariants}
            initial="hidden"
            animate={controls}
            className="py-24 bg-gray-50 overflow-hidden"
            id="services-section"
        >
            <div className="container mx-auto px-4 max-w-6xl">
                <motion.h2
                    variants={titleVariants}
                    className="text-5xl font-bold text-center text-gray-800 mb-6"
                >
                    Our Services
                </motion.h2>
                <motion.p
                    variants={titleVariants}
                    className="text-xl text-center max-w-3xl mx-auto mb-16"
                    style={{ color: '#10ac84' }}
                >
                    We offer cutting-edge solutions to help you make something amazing.
                </motion.p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
                    {services.map((service, index) => (
                        <ServiceCard key={index} service={service} index={index} />
                    ))}
                </div>
            </div>
        </motion.section>
    );
};

export default ServicesSection;