import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const TestimonialCard = ({ testimonial, index }) => {
    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true
    });

    const controls = useAnimation();

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    const cardVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
                delay: index * 0.1
            }
        }
    };

    return (
        <motion.div
            ref={ref}
            variants={cardVariants}
            initial="hidden"
            animate={controls}
            className="bg-white p-8 rounded-2xl shadow-xl flex flex-col min-h-[450px] transition-all duration-300 hover:shadow-2xl"
            whileHover={{ scale: 1.03 }}
        >
            <motion.div 
                className="flex items-center mb-6"
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
            >
                <img src={testimonial.image} alt={testimonial.name} className="w-20 h-20 rounded-full mr-4 shadow-md border-4 border-[#10ac84]" />
                <div>
                    <h4 className="text-2xl font-bold text-gray-800">{testimonial.name}</h4>
                    <p className="text-[#10ac84] font-medium">{testimonial.role}</p>
                </div>
            </motion.div>
            <motion.div 
                className="flex mb-4"
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.3 }}
            >
                {[...Array(5)].map((_, i) => (
                    <svg key={i} className="w-6 h-6 text-yellow-500 fill-current" viewBox="0 0 24 24">
                        <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
                    </svg>
                ))}
            </motion.div>
            <motion.p 
                className="text-gray-700 italic flex-grow text-lg leading-relaxed"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.4 }}
            >
                {testimonial.content}
            </motion.p>
            <motion.div 
                className="mt-6 text-sm text-[#10ac84] flex items-center font-semibold"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.5 }}
            >
                <span role="img" aria-label="verify" className="mr-2">✅</span>
                <p>Verified Client</p>
            </motion.div>
        </motion.div>
    );
};

const Testimonial = () => {
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true
    });

    const controls = useAnimation();

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    const sectionVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2
            }
        }
    };

    const titleVariants = {
        hidden: { opacity: 0, y: -30 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.8,
                ease: "easeOut"
            }
        }
    };

    const testimonials = [
        {
            name: "Dave Johnson",
            role: "E-commerce Entrepreneur",
            content: "SmartTech Devs transformed our online store with their cutting-edge web development services. The user experience is seamless, and our sales have skyrocketed. Their attention to detail and innovative solutions have given us a significant edge in the competitive e-commerce landscape.",
            image: "/images/testimonials/dave-johnson1.png"
        },
        {
            name: "Michael Chen",
            role: "CTO, FinTech Startup",
            content: "The AI-powered chatbot developed by SmartTech Devs has revolutionized our customer service. It handles inquiries with incredible accuracy and efficiency, allowing our team to focus on more complex issues. The ROI on this project has been phenomenal.",
            image: "/images/testimonials/michael-chen.jpg"
        },
        {
            name: "Emily Rodriguez",
            role: "Product Manager, SaaS Company",
            content: "Working with SmartTech Devs on our mobile app was a game-changer. Their expertise in both UI/UX design and app development resulted in an intuitive, beautiful product that our users love. The app's success has opened new revenue streams for our business.",
            image: "/images/testimonials/emily-rodriguez.jpg"
        }
    ];

    return (
        <motion.section
            ref={ref}
            variants={sectionVariants}
            initial="hidden"
            animate={controls}
            className="py-24 bg-gradient-to-br from-[#10ac84] to-[#0d8b6a] relative overflow-hidden"
        >
            {/* Enhanced Background Design */}
            <div className="absolute inset-0 z-0 opacity-30">
                <svg className="absolute w-full h-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="rgba(255,255,255,0.2)" fillOpacity="1" d="M0,128L48,144C96,160,192,192,288,192C384,192,480,160,576,165.3C672,171,768,213,864,218.7C960,224,1056,192,1152,165.3C1248,139,1344,117,1392,106.7L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
                </svg>
                <svg className="absolute w-full h-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="rgba(255,255,255,0.1)" fillOpacity="1" d="M0,256L48,240C96,224,192,192,288,181.3C384,171,480,181,576,186.7C672,192,768,192,864,170.7C960,149,1056,107,1152,106.7C1248,107,1344,149,1392,170.7L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
                </svg>
            </div>

            <div className="container mx-auto px-4 max-w-6xl relative z-10">
                <motion.h2 
                    variants={titleVariants}
                    className="text-5xl font-bold text-center text-white mb-6"
                >
                    Client Success Stories
                </motion.h2>
                <motion.p 
                    variants={titleVariants}
                    className="text-center text-white mb-16 text-xl max-w-3xl mx-auto"
                >
                    Discover how SmartTech Devs has helped businesses across various industries achieve their digital transformation goals and drive growth.
                </motion.p>
                <motion.div 
                    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10"
                    variants={sectionVariants}
                >
                    {testimonials.map((testimonial, index) => (
                        <TestimonialCard key={index} testimonial={testimonial} index={index} />
                    ))}
                </motion.div>
            </div>
        </motion.section>
    );
};

export default Testimonial;