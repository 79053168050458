import React from 'react';
import AboutUs from '../components/AboutUs';

const AboutUsPage = () => {
  return (
    <>
      <AboutUs />
    </>
  );
};

export default AboutUsPage;