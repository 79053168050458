// src/pages/showcase.js

import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Showcase from '../components/Showcase';

const ShowcasePage = () => {
  return (
    <>
      <Showcase />
    </>
  );
};

export default ShowcasePage;