import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const ContactSection = () => {
    const [formStatus, setFormStatus] = useState('');
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you would typically handle the form submission
        // For now, we'll just set a success message
        setFormStatus(`Thank you for your message. We'll get back to you soon!`);
    };

    const containerVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.8,
                ease: "easeOut",
                when: "beforeChildren",
                staggerChildren: 0.2
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
                ease: "easeOut"
            }
        }
    };

    return (
        <motion.section 
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={containerVariants}
            className="py-16 bg-[#10ac84] text-white relative overflow-hidden"
        >
            {/* Background design */}
            <div className="absolute inset-0 opacity-10">
                <div className="absolute top-0 left-0 w-40 h-40 bg-white rounded-full -translate-x-1/2 -translate-y-1/2"></div>
                <div className="absolute bottom-0 right-0 w-60 h-60 bg-white rounded-full translate-x-1/3 translate-y-1/3"></div>
            </div>

            <div className="container mx-auto px-4 relative z-10">
                <motion.div variants={itemVariants} className="max-w-2xl mx-auto text-center mb-8">
                    <h2 className="text-3xl font-bold mb-2">Tell us more about your project?</h2>
                    <p className="text-lg">Our expert will contact you to discuss your plans and goals</p>
                </motion.div>
                <motion.div variants={itemVariants} className="max-w-md mx-auto bg-white rounded-lg shadow-lg p-6">
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <motion.div variants={itemVariants}>
                            <input 
                                type="text" 
                                placeholder="Your Name" 
                                className="w-full p-2 rounded-md text-gray-800 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#10ac84]" 
                                required
                            />
                        </motion.div>
                        <motion.div variants={itemVariants}>
                            <input 
                                type="email" 
                                placeholder="Your Email" 
                                className="w-full p-2 rounded-md text-gray-800 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#10ac84]" 
                                required
                            />
                        </motion.div>
                        <motion.div variants={itemVariants}>
                            <textarea 
                                placeholder="Tell us about your project" 
                                rows="3" 
                                className="w-full p-2 rounded-md text-gray-800 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#10ac84]"
                                required
                            ></textarea>
                        </motion.div>
                        <motion.div variants={itemVariants}>
                            <button 
                                type="submit" 
                                className="w-full bg-[#10ac84] text-white py-2 px-4 rounded-md font-semibold hover:bg-[#0d8b6a] transition duration-300"
                            >
                                Let's Connect!
                            </button>
                        </motion.div>
                    </form>
                    {formStatus && (
                        <motion.div 
                            className="mt-4 text-center text-green-600"
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            {formStatus}
                        </motion.div>
                    )}
                </motion.div>
            </div>
        </motion.section>
    );
};

export default ContactSection;