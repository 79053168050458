import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import WebDevelopment from '../components/GenerativeAI';

const GenerativeAIPage = () => {
  return (
    <>
      <WebDevelopment />
    </>
  );
};

export default GenerativeAIPage;