import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const BenefitItem = ({ imageSrc, title, description, isReversed, borderColor }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const variants = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: isReversed ? 50 : -50 },
  };

  return (
    <div className={`flex flex-col ${isReversed ? 'md:flex-row-reverse' : 'md:flex-row'} items-center mb-24`}>
      <motion.div 
        ref={ref}
        className="w-full md:w-1/2 p-4"
        initial="hidden"
        animate={controls}
        variants={variants}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
      >
        <img 
          src={imageSrc} 
          alt={title} 
          className="w-full h-[300px] object-cover rounded-lg shadow-xl" 
        />
      </motion.div>
      <motion.div 
        className="w-full md:w-1/2 p-4"
        initial="hidden"
        animate={controls}
        variants={{
          visible: { opacity: 1, x: 0 },
          hidden: { opacity: 0, x: isReversed ? -50 : 50 },
        }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
      >
        <div className={`bg-white rounded-lg shadow-lg p-8 h-full border-b-4 ${borderColor}`}>
          <h3 className="text-2xl font-bold mb-4 text-gray-800">{title}</h3>
          <p className="text-base text-gray-600 leading-relaxed">{description}</p>
        </div>
      </motion.div>
    </div>
  );
};

const CompanyInfo = ({ title, content }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div 
      ref={ref}
      className="flex-1 p-6 bg-white rounded-lg shadow-md h-full"
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
      }}
      transition={{ duration: 0.8, ease: "easeOut" }}
    >
      <h3 className="text-xl font-bold mb-3 text-gray-800">{title}</h3>
      <p className="text-sm text-gray-600 leading-relaxed">{content}</p>
    </motion.div>
  );
};

const WhyChooseUs = () => {
  const features = [
    {
      title: "AI-Powered Solutions",
      description: "Harness the power of artificial intelligence to revolutionize your business processes. Our cutting-edge AI solutions are designed to streamline operations, enhance decision-making, and drive innovation across your organization.",
      imageSrc: "/images/ai-solutions.jpg",
      borderColor: "border-blue-500"
    },
    {
      title: "Expert Web Development",
      description: "Transform your online presence with our expert web development services. We craft visually stunning, highly functional websites that not only captivate your audience but also drive conversions.",
      imageSrc: "/images/web-development.jpg",
      borderColor: "border-green-500"
    },
    {
      title: "Innovative App Development",
      description: "Bring your app ideas to life with our innovative mobile application development services. Whether you're targeting iOS, Android, or both, our experienced developers create powerful, user-friendly apps that deliver exceptional experiences.",
      imageSrc: "/images/app-development.jpg",
      borderColor: "border-pink-500"
    },
    {
      title: "Affordable Pricing",
      description: "Experience top-tier technology solutions without breaking the bank. Our competitive pricing strategy is designed to provide maximum value for your investment. We believe that cutting-edge technology should be accessible to businesses of all sizes.",
      imageSrc: "/images/affordable-pricing.jpg",
      borderColor: "border-yellow-500"
    },
    {
      title: "Custom Solutions",
      description: "One size doesn't fit all in the world of technology. That's why we specialize in creating tailor-made software solutions that address your unique business challenges. Our team works closely with you to understand your specific requirements, pain points, and goals.",
      imageSrc: "/images/custom-solutions1.jpg",
      borderColor: "border-purple-500"
    },
    {
      title: "24/7 Support",
      description: "We're committed to your success around the clock. Our dedicated support team is available 24/7 to ensure your systems run smoothly at all times. Whether you need technical assistance, have questions, or encounter issues, our expert support staff is just a call or click away.",
      imageSrc: "/images/support1.jpg",
      borderColor: "border-red-500"
    }
  ];
  
  const companyInfo = [
    {
      title: "Our Story",
      content: "Founded by ambitious local entrepreneurs, SmartTech Devs is a proudly self-made company that stands as a beacon of inspiration and hope for the youth. Fueled by a passion for innovation, we have defied the odds to make a significant impact in the tech industry."
    },
    {
      title: "What We Believe In",
      content: "We believe in the power of technology, hard work, and professionalism to deliver top-notch services. Committed to skill development and continuous learning, we stay ahead in the ever-evolving tech landscape."
    },
    {
      title: "Milestones",
      content: "We've delivered AI-driven solutions to over 100 enterprise clients, enhancing their operations. Our flagship platform has processed over 1 million transactions, boosting efficiency by 40% and expanding our reach across 20 countries."
    }
  ];

  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <section className="py-24 bg-gray-50 overflow-hidden">
      <div className="container mx-auto px-4 max-w-6xl">
        <motion.h2 
          ref={ref}
          className="text-5xl font-bold text-center mb-16 text-gray-800"
          initial="hidden"
          animate={controls}
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: -50 },
          }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          Why Choose Us
        </motion.h2>
        <div className="space-y-24">
          {features.map((feature, index) => (
            <BenefitItem 
              key={index}
              imageSrc={feature.imageSrc}
              title={feature.title}
              description={feature.description}
              isReversed={index % 2 !== 0}
              borderColor={feature.borderColor}
            />
          ))}
        </div>
        <div className="mt-24 grid grid-cols-1 md:grid-cols-3 gap-8">
          {companyInfo.map((info, index) => (
            <CompanyInfo key={index} title={info.title} content={info.content} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;