import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import ServicesSection from './components/ServicesSection';
import WhyChooseUs from './components/WhyChooseUs';
import Testimonial from './components/Testimonial';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';
import Article from './components/Article';
import WebDevelopmentPage from './pages/web-development';
import AppDevelopmentPage from './pages/app-development';
import GenerativeAIPage from './pages/ai-generative';
import UIUXDesignPage from './pages/ui-ux-design';
import AboutUsPage from './pages/about-us';
import ContactUsPage from './pages/contact-us';
import ShowcasePage from './pages/showcase';

function HomePage() {
  return (
    <>
      <HeroSection />
      <ServicesSection />
      <WhyChooseUs />
      <Testimonial />
      <Article />
      <ContactSection />
    </>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about-us" element={<AboutUsPage/>} />
          <Route path="/contact" element={<ContactUsPage  />} />
          <Route path="/showcase" element={<ShowcasePage />} />
          <Route path="/services/web-development" element={<WebDevelopmentPage />} />
          <Route path="/services/app-development" element={<AppDevelopmentPage />} />
          <Route path="/services/generative-ai" element={<GenerativeAIPage />} />
          <Route path="/services/ui-ux-design" element={<UIUXDesignPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;