import React from 'react';
import { motion } from 'framer-motion';

const HeroSection = () => {
    const scrollToServices = () => {
        const servicesSection = document.getElementById('services-section');
        if (servicesSection) {
            servicesSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <section className="bg-white py-24 overflow-hidden">
            <div className="container mx-auto px-4 max-w-6xl">
                <div className="flex flex-col md:flex-row items-center justify-between relative">
                    <motion.div
                        className="md:w-1/2 mb-10 md:mb-0 z-10"
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.8 }}
                    >
                        <motion.h1 
                            className="text-5xl md:text-6xl font-bold leading-tight mb-6 text-gray-800"
                            initial={{ opacity: 0, y: -30 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8, delay: 0.2 }}
                        >
                            Transform Your
                            <br />
                            <motion.span
                                className="inline-block text-[#10ac84]"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.5, duration: 0.8 }}
                            >
                                Ideas
                            </motion.span> into
                            <br />
                            Reality
                        </motion.h1>
                        <motion.p 
                            className="text-xl text-gray-600 mb-8"
                            initial={{ opacity: 0, y: 30 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8, delay: 0.4 }}
                        >
                            We create cutting-edge software solutions that empower you to turn your ideas into something extraordinary.
                        </motion.p>
                        <motion.button
                            className="bg-[#10ac84] text-white py-3 px-8 rounded-lg text-lg font-semibold hover:bg-[#0d8b6a] transition duration-300"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={scrollToServices}
                            initial={{ opacity: 0, y: 30 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8, delay: 0.6 }}
                        >
                            Let's Explore!
                        </motion.button>
                    </motion.div>
                    <motion.div
                        className="md:w-1/2 flex justify-end z-0 md:-mt-20"
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.8 }}
                    >
                        <motion.img
                            src="/images/team/check7.png"
                            alt="Team Member"
                            className="w-[500px] max-h-[600px] h-auto  rounded-lg  object-cover bg-transparent"
                            initial={{ y: 10 }}
                            animate={{ y: 0 }}
                            transition={{
                                y: {
                                    duration: 2,
                                    repeat: Infinity,
                                    repeatType: "reverse",
                                    ease: "easeInOut"
                                }
                            }}
                        />
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;