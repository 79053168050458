import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

// Animation variants for text
const textVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
};

// Staggered animation for children
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

// Animated heading component
const AnimatedHeading = ({ children, className }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.h2
      ref={ref}
      className={className}
      initial="hidden"
      animate={controls}
      variants={{
        hidden: { opacity: 0, y: -20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } }
      }}
    >
      {children}
    </motion.h2>
  );
};

// Animated paragraph component
const AnimatedParagraph = ({ children, className }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.p
      ref={ref}
      className={className}
      variants={textVariants}
      initial="hidden"
      animate={controls}
      transition={{ duration: 0.6, ease: "easeOut" }}
    >
      {children}
    </motion.p>
  );
};

const TechIcon = ({ tech, index }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center justify-center"
      initial="hidden"
      animate={controls}
      variants={{
        hidden: { opacity: 0, y: 20 },
        visible: { 
          opacity: 1, 
          y: 0, 
          transition: { 
            duration: 0.5,
            delay: index * 0.1 
          } 
        }
      }}
    >
      <motion.img
        src={tech.path}
        alt={tech.name}
        className="w-16 h-16 object-contain mb-4"
        whileHover={{ 
          rotate: 360,
          transition: { 
            duration: 0.5
          }
        }}
      />
      <span className="text-sm font-medium text-gray-700 text-center">
        {tech.name}
      </span>
    </motion.div>
  );
};

const TechSection = ({ techStacks }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <section ref={ref} className="py-24 bg-gray-50">
      <div className="container mx-auto px-4 max-w-6xl">
        <motion.div
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { opacity: 0 },
            visible: { 
              opacity: 1,
              transition: {
                duration: 0.5
              }
            }
          }}
        >
          <p className="text-center text-[#10ac84] text-lg font-semibold mb-2">
            Tools & Technologies
          </p>
          <h2 className="text-5xl font-bold text-center text-gray-800 mb-6">
            Cutting-Edge Tech for Generative AI
          </h2>
          <p className="text-center text-gray-600 mb-16 max-w-2xl mx-auto">
            We leverage the most advanced AI frameworks and tools to build powerful Generative AI solutions
          </p>
        </motion.div>
        
        <motion.div 
          className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6"
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { opacity: 0 },
            visible: { 
              opacity: 1,
              transition: {
                duration: 0.3
              }
            }
          }}
        >
          {techStacks.map((tech, index) => (
            <TechIcon key={index} tech={tech} index={index} />
          ))}
        </motion.div>
      </div>
    </section>
  );
};

const ProcessItem = ({ icon, title, description }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div 
      ref={ref}
      className="bg-white p-6 rounded-lg shadow-lg transition-all duration-300"
      initial="hidden"
      animate={controls}
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
      }}
      whileHover={{
        scale: 1.05
      }}
    >
      <div className="text-[#10ac84] text-4xl mb-4">{icon}</div>
      <h3 className="text-xl font-semibold text-gray-800 mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </motion.div>
  );
};

const GenerativeAI = () => {
  const techStacks = [
    { path: '/images/tech/tensorflow.svg', name: 'TensorFlow' },
    { path: '/images/tech/pytorch.png', name: 'PyTorch' },
    { path: '/images/tech/openai.png', name: 'OpenAI' },
    { path: '/images/tech/huggingface.svg', name: 'Hugging Face' },
    { path: '/images/tech/langchain.svg', name: 'LangChain' },
    { path: '/images/tech/pinecone.png', name: 'Pinecone' },
    { path: '/images/tech/llamaindex.svg', name: 'LlamaIndex' },
    { path: '/images/tech/aws.png', name: 'AWS' },
    { path: '/images/tech/azure.png', name: 'Azure' },
    { path: '/images/tech/claude.png', name: 'Claude' },
    { path: '/images/tech/gemini.png', name: 'Gemini' },
    { path: '/images/tech/leonardo.svg', name: 'Leonardo' },
  ];

  const ScrollTriggeredSection = ({ children, className }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.1,
    });

    useEffect(() => {
      if (inView) {
        controls.start('visible');
      }
    }, [controls, inView]);

    return (
      <motion.section
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={containerVariants}
        className={className}
      >
        {children}
      </motion.section>
    );
  };


  const processes = [
    {
      icon: "🧠",
      title: "Advanced AI Models",
      description: "We leverage state-of-the-art AI models to generate human-like text, images, and more, pushing the boundaries of what's possible in AI."
    },
    {
      icon: "🔒",
      title: "Ethical AI",
      description: "Our Generative AI solutions are built with strong ethical considerations, ensuring responsible and unbiased outputs."
    },
    {
      icon: "🚀",
      title: "Scalable Solutions",
      description: "Our Generative AI platforms are designed to scale effortlessly, handling everything from small tasks to enterprise-level operations."
    }
  ];

  return (
    <div className="overflow-hidden">
      <ScrollTriggeredSection className="relative bg-gray-900 text-white py-24">
        <div 
          className="absolute inset-0 bg-cover bg-center z-0"
          style={{
            backgroundImage: "url('/images/generative-ai-bg.jpg')",
            filter: "brightness(33%)",
            backgroundSize: "cover",
            backgroundPosition: "center 30%",
          }}
        ></div>
        <div className="absolute inset-0 bg-blue-500 z-10 mix-blend-overlay opacity-35"></div>
        <div className="container mx-auto px-4 max-w-6xl relative z-20">
          <motion.h1 
            className="text-5xl font-bold mb-6"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            Generative AI
          </motion.h1>
          <motion.nav 
            className="text-sm mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            <ol className="list-none p-0 inline-flex">
              <li className="flex items-center">
                <Link to="/" className="text-gray-400 hover:text-white">Home</Link>
                <span className="mx-2 text-gray-600">•</span>
              </li>
              <li>
                <span className="text-gray-300">Generative AI</span>
              </li>
            </ol>
          </motion.nav>
        </div>
      </ScrollTriggeredSection>
      
      <ScrollTriggeredSection className="py-24 bg-gray-100">
        <div className="container mx-auto px-4 max-w-6xl">
          <div className="flex flex-col md:flex-row items-stretch space-y-12 md:space-y-0 md:space-x-12">
            <motion.div 
              className="md:w-1/2 flex items-center"
              variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
              }}
            >
              <motion.img 
                src="/images/generative-ai-showcase.jpg" 
                alt="Generative AI Showcase" 
                className="rounded-lg shadow-xl w-full h-full object-cover"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              />
            </motion.div>
            <motion.div 
              className="md:w-1/2 flex flex-col justify-center"
              variants={containerVariants}
            >
              <AnimatedHeading className="text-2xl font-semibold text-[#10ac84] mb-4">
                Cutting-edge Generative AI
              </AnimatedHeading>
              <AnimatedHeading className="text-4xl font-bold text-gray-800 mb-6">
                Unleash the Power of AI-Generated Content
              </AnimatedHeading>
              <AnimatedParagraph className="text-gray-600 mb-4">
                At SmartTech Devs, we're at the forefront of the Generative AI revolution. Our advanced AI models can create human-like text, breathtaking images, and innovative designs, opening up new realms of possibility for your business.
              </AnimatedParagraph>
              <AnimatedParagraph className="text-gray-600 mb-6">
                From content creation to product design, our Generative AI solutions are transforming how businesses operate. Harness the power of AI to boost productivity, enhance creativity, and stay ahead in today's fast-paced digital landscape.
              </AnimatedParagraph>
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link 
                  to="/contact" 
                  className="bg-[#10ac84] text-white py-3 px-8 rounded-lg text-lg font-semibold hover:bg-[#0d8b6a] transition duration-300 inline-block"
                >
                  Explore Generative AI
                </Link>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </ScrollTriggeredSection>
  
      <TechSection techStacks={techStacks} />
  
      <ScrollTriggeredSection className="py-24 bg-gray-100">
        <div className="container mx-auto px-4 max-w-6xl">
          <motion.div variants={containerVariants}>
            <AnimatedParagraph className="text-center text-[#10ac84] text-lg font-semibold mb-2">
              Our Approach
            </AnimatedParagraph>
            <AnimatedHeading className="text-5xl font-bold text-center text-gray-800 mb-12">
              Bridging Imagination and Reality with AI
            </AnimatedHeading>
            <AnimatedParagraph className="text-center text-gray-600 mb-16 max-w-3xl mx-auto">
              At SmartTech Devs, we design our Generative AI solutions with your business growth in mind. Our AI models are built to be scalable and adaptable, evolving alongside your enterprise. We employ a rigorous development process, ensuring high-quality, ethical AI solutions.
            </AnimatedParagraph>
          </motion.div>
          <motion.div transition={{ duration: 0.6 }}>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {processes.map((process, index) => (
              <ProcessItem key={index} {...process} />
            ))}
          </div>
          </motion.div>
        </div>
      </ScrollTriggeredSection>
  
      <ScrollTriggeredSection className="py-24 bg-white">
        <div className="container mx-auto px-4 max-w-6xl">
          <motion.div variants={containerVariants}>
            <AnimatedParagraph className="text-center text-[#10ac84] text-lg font-semibold mb-2">
              Our Process
            </AnimatedParagraph>
            <AnimatedHeading className="text-5xl font-bold text-center text-gray-800 mb-12">
              Generative AI Development Lifecycle
            </AnimatedHeading>
          </motion.div>
          
          <div className="flex flex-col md:flex-row items-stretch space-y-12 md:space-y-0 md:space-x-12">
            <motion.div 
              className="md:w-1/2 flex flex-col justify-center"
              variants={containerVariants}
            >
              <AnimatedParagraph className="text-gray-600 mb-6">
                SmartTech Devs brings years of expertise in developing cutting-edge Generative AI solutions. Our comprehensive skillset spans both open-source and proprietary AI technologies, enabling us to tackle the most challenging and complex AI projects while meeting customer requirements, expectations, and preferences.
              </AnimatedParagraph>
              <AnimatedParagraph className="text-gray-600 mb-6">
                To optimize performance and ensure effective results, we assemble well-balanced project teams tailored to your AI goals and technical requirements. Our data scientists, AI engineers, software architects, and project managers leverage SmartTech Devs knowledge management system and continuous process improvements.
              </AnimatedParagraph>
              <AnimatedParagraph className="text-gray-600">
                Our extensive hands-on implementation experience allows us to extract only the most efficient practices from all methodologies, focusing on real needs in an efficient, transparent, and predictable process.
              </AnimatedParagraph>
            </motion.div>
            
            <motion.div 
              className="md:w-1/2 relative h-80 md:h-auto"
              variants={{
                hidden: { opacity: 0, x: 50 },
                visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
              }}
            >
              <motion.img 
                src="/images/generative-ai-process.jpg" 
                alt="Generative AI Process" 
                className="absolute inset-0 w-full h-full object-cover rounded-lg"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              />
            </motion.div>
          </div>
          
          <motion.div 
            className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-24"
            variants={containerVariants}
            transition={{ duration: 0.3 }}
          >
          {[
            { icon: "📊", title: "Data Collection" },
            { icon: "🧠", title: "Model Training" },
            { icon: "🔧", title: "Fine-tuning" },
            { icon: "🚀", title: "Deployment" },
            { icon: "📈", title: "Monitoring" },
            { icon: "🔄", title: "Iteration" }
          ].map((item, index) => (
            <motion.div 
              key={index}
              className="bg-white p-8 rounded-lg shadow-md flex items-center space-x-6"
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0, transition: { duration: 1, delay: index * 0.2 } }
              }}
              
            >
              <span className="text-4xl">{item.icon}</span>
              <span className="text-xl font-semibold text-gray-800">{item.title}</span>
            </motion.div>
          ))}
        </motion.div>
        </div>
      </ScrollTriggeredSection>
  
      <ScrollTriggeredSection className="relative py-24 bg-gray-900 text-white overflow-hidden">
        <div 
          className="absolute inset-0 bg-cover bg-center z-0"
          style={{
            backgroundImage: "url('/images/generative-ai-bg2.jpg')",
            filter: "brightness(35%)",
            backgroundSize: "cover",
            backgroundPosition: "center 30%",
          }}
        ></div>
        <div className="absolute inset-0 bg-blue-500 z-10 mix-blend-overlay opacity-35"></div>
        
        <div className="container mx-auto px-4 max-w-6xl relative z-20">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0">
            <motion.div 
              className="md:w-2/3"
              variants={containerVariants}
              transition={{ duration: 0.3 }}
            >
              <AnimatedHeading className="text-4xl font-bold mb-4">
                Ready to harness the power of Generative AI?
              </AnimatedHeading>
              <AnimatedParagraph className="text-xl max-w-2xl">
                Let's transform your business with cutting-edge AI technology. Our team is ready to bring your AI vision to life.
              </AnimatedParagraph>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Link 
                to="/contact" 
                className="bg-[#10ac84] text-white py-3 px-8 rounded-lg text-lg font-semibold hover:bg-[#0d8b6a] transition duration-300 inline-block"
              >
                Start Your AI Journey
              </Link>
            </motion.div>
          </div>
        </div>
      </ScrollTriggeredSection>
    </div>
  );
};

export default GenerativeAI;