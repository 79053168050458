import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
    const [isServicesOpen, setIsServicesOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const location = useLocation();

    const services = [
        {
            title: "Web Development",
            description: "Dynamic, high-performing websites",
            image: "/images/services/webDevelopment.jpg",
            link: "/services/web-development"
        },
        {
            title: "App Development",
            description: "Custom iOS, Android, and cross-platform apps",
            image: "/images/services/appDevelopment.jpg",
            link: "/services/app-development"
        },
        {
            title: "Generative AI",
            description: "Leverage the power of AI to create innovative, efficient solutions for your business",
            image: "/images/services/generative-ai1.jpg",
            link: "/services/generative-ai"
        },
        {
            title: "UI/UX Design",
            description: "Stunning, intuitive designs",
            image: "/images/services/ux-ui-design.jpg",
            link: "/services/ui-ux-design"
        }
    ];

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        // Scroll to top when route changes
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        setIsMobileMenuOpen(false);
    };

    return (
        <header 
            className={`fixed top-0 left-0 right-0 transition-all duration-300 bg-white ${
                isScrolled ? 'shadow-md' : ''
            } py-4 z-50`}
        >
            <div className="container mx-auto flex justify-between items-center px-4">
                <div className="flex items-center">
                    <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                        <img src="/images/logo/green-logo.png" alt="Logo" className="h-12" />
                    </Link>
                </div>
                <nav className="hidden md:flex items-center space-x-8">
                    {['Home', 'About Us', 'Services', 'Showcase'].map((item, index) => (
                        <div key={index} className="relative group">
                            {item === 'Services' ? (
                                <span 
                                    className="cursor-pointer transition-colors duration-300 text-gray-800"
                                    onMouseEnter={() => setIsServicesOpen(true)}
                                >
                                    {item}
                                </span>
                            ) : (
                                <Link 
                                    to={item === 'Home' ? '/' : `/${item.toLowerCase().replace(' ', '-')}`}
                                    className="transition-colors duration-300 text-gray-800"
                                    onClick={() => window.scrollTo(0, 0)}
                                >
                                    {item}
                                </Link>
                            )}
                            <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#10ac84] transition-all duration-300 group-hover:w-full"></span>
                        </div>
                    ))}
                </nav>
                <div className="md:hidden">
                    <button onClick={toggleMobileMenu} className="text-gray-800 hover:text-gray-600">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>
                </div>
                <Link 
                    to="/contact" 
                    className="hidden md:inline-block transition-colors duration-300 bg-[#10ac84] text-white hover:bg-[#0d8b6a] py-2 px-6 rounded-full"
                    onClick={() => window.scrollTo(0, 0)}
                >
                    Contact Us
                </Link>
            </div>
            {isMobileMenuOpen && (
    <div className="md:hidden bg-white py-4">
        <nav className="flex flex-col items-center space-y-4">
            {['Home', 'About Us', 'Services', 'Showcase'].map((item, index) => (
                <Link 
                    key={index}
                    to={item === 'Home' ? '/' : item === 'Services' ? '/#services-section' : `/${item.toLowerCase().replace(' ', '-')}`}
                    className="text-gray-800 hover:text-[#10ac84]"
                    onClick={() => {
                        setIsMobileMenuOpen(false);
                        if (item === 'Services') {
                            setTimeout(() => {
                                const servicesSection = document.getElementById('services-section');
                                if (servicesSection) {
                                    servicesSection.scrollIntoView({ behavior: 'smooth' });
                                }
                            }, 100);
                        } else {
                            window.scrollTo(0, 0);
                        }
                    }}
                >
                    {item}
                </Link>
            ))}
            <Link 
                to="/contact" 
                className="bg-[#10ac84] text-white hover:bg-[#0d8b6a] py-2 px-6 rounded-full"
                onClick={() => {
                    setIsMobileMenuOpen(false);
                    window.scrollTo(0, 0);
                }}
            >
                Contact Us
            </Link>
        </nav>
    </div>
)}
         
            {isServicesOpen && (
                <div 
                    className="absolute left-0 right-0 bg-white shadow-lg overflow-hidden mt-4 z-40"
                    onMouseEnter={() => setIsServicesOpen(true)}
                    onMouseLeave={() => setIsServicesOpen(false)}
                >
                    <div className="container mx-auto flex flex-wrap justify-between py-8 px-4">
                        {services.map((service, index) => (
                            <Link 
                                key={index} 
                                to={service.link}
                                className="flex flex-col items-center w-full sm:w-1/2 md:w-1/4 px-2 group transition-all duration-300 hover:bg-[#10ac84] rounded-lg py-4 mb-4 md:mb-0"
                                onClick={() => {
                                    setIsServicesOpen(false);
                                    window.scrollTo(0, 0);
                                }}
                            >
                                <img 
                                    src={service.image} 
                                    alt={service.title} 
                                    className="w-32 h-32 object-cover rounded-lg mb-4 transition duration-300 group-hover:scale-105"
                                />
                                <h3 className="text-lg font-semibold text-gray-800 group-hover:text-white text-center mb-2">{service.title}</h3>
                                <p className="text-sm text-gray-600 group-hover:text-white text-center">{service.description}</p>
                            </Link>
                        ))}
                    </div>
                </div>
            )}
        </header>
    );
};

export default Header;