import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const WebDevelopment = () => {
  const techStacks = [
    { path: '/images/tech/react.png' },
    { path: '/images/tech/vue.png' },
    { path: '/images/tech/angular.png' },
    { path: '/images/tech/bootstrap.png' },
    { path: '/images/tech/laravel.png' },
    { path: '/images/tech/nodejs.png' },
    { path: '/images/tech/python.png' },
    { path: '/images/tech/aspnet.png' },
    { path: '/images/tech/mysql.png' },
    { path: '/images/tech/mssql.png' },
    { path: '/images/tech/postgresql.png' },
    { path: '/images/tech/mongodb.png' },
    { path: '/images/tech/wordpress.png' },
    { path: '/images/tech/opencart.png' },
    { path: '/images/tech/joomla.png' },
    { path: '/images/tech/magento.png' },
  ];  

  const ProcessItem = ({ icon, title, description }) => {
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.2,
    });
    const controls = useAnimation();

    useEffect(() => {
      if (inView) {
        controls.start('visible');
      }
    }, [controls, inView]);

    return (
      <motion.div 
        ref={ref}
        className="bg-white p-6 rounded-lg shadow-lg"
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { 
            opacity: 1, 
            y: 0,
            transition: {
              duration: 0.8,
              ease: "easeOut"
            }
          }
        }}
        whileHover={{ scale: 1.05 }}
      >
        <div className="text-[#10ac84] text-4xl mb-4">{icon}</div>
        <h3 className="text-xl font-semibold text-gray-800 mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </motion.div>
    );
  };

  const processes = [
    {
      icon: "🔍",
      title: "Transparency",
      description: "We keep you in the loop at every stage. Our development process is open, with regular updates and clear communication, ensuring your project stays on track and within budget."
    },
    {
      icon: "👍",
      title: "Reliability",
      description: "Quality is our priority. Each development phase undergoes rigorous testing and review, guaranteeing that your web solution meets and exceeds all specified requirements."
    },
    {
      icon: "🚀",
      title: "Time-to-market",
      description: "We understand the importance of speed in the digital world. Our agile approach ensures rapid development and deployment, allowing you to launch your web presence quickly and efficiently."
    }
  ];

  const processItems = [
    { emoji: "🔄", title: "Project Communication" },
    { emoji: "📋", title: "Requirements Management" },
    { emoji: "🔄", title: "Change Management" },
    { emoji: "⚠️", title: "Risk Management" },
    { emoji: "✅", title: "Quality Assurance" },
    { emoji: "🤝", title: "Competence Transfer" },
  ];

  const ScrollTriggeredSection = ({ children, className }) => {
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.2,
    });
    const controls = useAnimation();

    useEffect(() => {
      if (inView) {
        controls.start('visible');
      }
    }, [controls, inView]);

    return (
      <motion.section
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0 },
          visible: {
            opacity: 1,
            transition: {
              staggerChildren: 0.3,
              duration: 0.8
            }
          }
        }}
        className={className}
      >
        {children}
      </motion.section>
    );
  };

  return (
    <div className="overflow-hidden">
      <section className="relative bg-gray-900 text-white py-24">
        <div 
          className="absolute inset-0 bg-cover bg-center z-0"
          style={{
            backgroundImage: "url('/images/web-development1-bg.jpg')",
            filter: "brightness(40%)",
            backgroundSize: "100% auto",
            backgroundPosition: "center 30%",
          }}
        ></div>
        <div className="absolute inset-0 bg-blue-500 z-10 mix-blend-overlay opacity-35"></div>
        <div className="container mx-auto px-4 max-w-6xl relative z-20">
          <motion.h1 
            className="text-5xl font-bold mb-6"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Web Development
          </motion.h1>
          <nav className="text-sm mb-4">
            <ol className="list-none p-0 inline-flex">
              <li className="flex items-center">
                <Link to="/" className="text-gray-400 hover:text-white">Home</Link>
                <span className="mx-2 text-gray-600">•</span>
              </li>
              <li>
                <span className="text-gray-300">Web Development</span>
              </li>
            </ol>
          </nav>
        </div>
      </section>

      <ScrollTriggeredSection className="py-24 bg-gray-100">
        <div className="container mx-auto px-4 max-w-6xl">
          <div className="flex flex-col md:flex-row items-stretch space-y-12 md:space-y-0 md:space-x-12">
            <motion.div 
              className="md:w-1/2 flex flex-col justify-center"
              variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { 
                  opacity: 1, 
                  x: 0,
                  transition: {
                    duration: 0.8,
                    ease: "easeOut"
                  }
                }
              }}
            >
              <h2 className="text-2xl font-semibold text-[#10ac84] mb-4">Web Development Excellence</h2>
              <h3 className="text-4xl font-bold text-gray-800 mb-6">
                Transforming Your Vision into Powerful Web Solutions
              </h3>
              <p className="text-gray-600 mb-4">
                In today's digital age, having a strong online presence is essential for business success. At SmartTech Devs, we specialize in creating custom web solutions that go beyond your expectations. Our skilled developers work to turn your ideas into reliable, scalable, and easy-to-use web applications.
              </p>
              <p className="text-gray-600 mb-4">
                From designs that look great on any device to powerful systems that manage complex tasks smoothly, we offer complete web development services tailored to your specific needs. Our solutions are built for performance, designed to impress, and ready to grow with your business.
              </p>
            </motion.div>
            <motion.div 
              className="md:w-1/2 flex items-center"
              variants={{
                hidden: { opacity: 0, x: 50 },
                visible: { 
                  opacity: 1, 
                  x: 0,
                  transition: {
                    duration: 0.9,
                    ease: "easeOut"
                  }
                }
              }}
            >
              <motion.img 
                src="/images/web-development-showcase.jpg" 
                alt="Web Development Showcase" 
                className="rounded-lg shadow-xl w-full h-full object-cover"
                animate={{ y: [0, -10, 0] }}
                transition={{ 
                  duration: 4,
                  repeat: Infinity,
                  repeatType: "reverse",
                  ease: "easeInOut"
                }}
              />
            </motion.div>
          </div>
        </div>
      </ScrollTriggeredSection>

      <ScrollTriggeredSection className="py-24 bg-white">
        <div className="container mx-auto px-4 max-w-6xl">
          <motion.p 
            className="text-center text-[#10ac84] text-lg font-semibold mb-4"
            variants={{
              hidden: { opacity: 0, y: -20 },
              visible: { 
                opacity: 1, 
                y: 0,
                transition: {
                  duration: 0.5,
                  ease: "easeOut"
                }
              }
            }}
          >
            Tools & Technologies
          </motion.p>
          <motion.h2 
            className="text-center text-4xl font-bold text-gray-800 mb-12"
            variants={{
              hidden: { opacity: 0, y: -30 },
              visible: { 
                opacity: 1, 
                y: 0,
                transition: {
                  duration: 1,
                  delay: 0.3,
                  ease: "easeOut"
                }
              }
            }}
          >
            Tech Stacks For Modern-Day Web Apps
          </motion.h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-8 max-w-4xl mx-auto">
            {techStacks.map((tech, index) => (
              <motion.div 
                key={index} 
                className="flex flex-col items-center"
                variants={{
                  hidden: { opacity: 0, y: 50 },
                  visible: { 
                    opacity: 1, 
                    y: 0,
                    transition: {
                      duration: 0.5,
                      delay: index * 0.1,
                      ease: "easeOut"
                    }
                  }
                }}
              >
                <img 
                  src={tech.path} 
                  alt={tech.name} 
                  className="h-12 mb-2 transition-transform duration-300 ease-in-out transform hover:scale-110" 
                />
              </motion.div>
            ))}
          </div>
        </div>
      </ScrollTriggeredSection>

      <ScrollTriggeredSection className="py-24 bg-gray-100">
        <div className="container mx-auto px-4 max-w-6xl">
          <motion.p 
            className="text-center text-[#10ac84] text-lg font-semibold mb-4"
            variants={{
              hidden: { opacity: 0, y: -20 },
              visible: { 
                opacity: 1, 
                y: 0,
                transition: {
                  duration: 1,
                  delay: 0.2,
                  ease: "easeOut"
                }
              }
            }}
          >
            How we do it
          </motion.p>
          <motion.h2 
            className="text-4xl font-bold text-center text-gray-800 mb-12"
            variants={{
              hidden: { opacity: 0, y: -30 },
              visible: { 
                opacity: 1, 
                y: 0,
                transition: {
                  duration: 1,
                  delay: 0.2,
                  ease: "easeOut"
                }
              }
            }}
          >
            Business hand in hand with our technology
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {processes.map((process, index) => (
              <ProcessItem key={index} {...process} />
            ))}
          </div>
        </div>
      </ScrollTriggeredSection>

      <ScrollTriggeredSection className="py-24 bg-white">
        <div className="container mx-auto px-4 max-w-6xl">
          <motion.p 
            className="text-[#10ac84] text-lg font-semibold mb-4"
            variants={{
              hidden: { opacity: 0, y: -20 },
              visible: { 
                opacity: 1, 
                y: 0,
                transition: {
                  duration: 1,
                  delay: 0.2,
                  ease: "easeOut"
                }
              }
            }}
          >
            Project Management
          </motion.p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <motion.div 
              className="md:col-span-1"
              variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { 
                  opacity: 1, 
                  x: 0,
                  transition: {
                    duration: 0.8,
                    ease: "easeOut"
                  }
                }
              }}
            >
              <h2 className="text-3xl font-bold text-gray-800 mb-4">The Process</h2>
              <p className="text-gray-600">
                An extensive hands-on implementation experience allows SmartTech Devs to extract
                only the most efficient practices from all the used methodologies taking into
                account only real needs in the efficient, transparent and predictable process.
              </p>
            </motion.div>
            <div className="md:col-span-2 grid grid-cols-2 md:grid-cols-3 gap-6">
              {processItems.map((item, index) => (
                <motion.div 
                  key={index} 
                  className="bg-gray-100 p-6 rounded-lg"
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { 
                      opacity: 1, 
                      y: 0,
                      transition: {
                        duration: 0.8,
                        delay: index * 0.1,
                        ease: "easeOut"
                      }
                    }
                  }}
                >
                  <div className="text-4xl mb-2">{item.emoji}</div>
                  <h3 className="text-lg font-semibold text-gray-800">{item.title}</h3>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </ScrollTriggeredSection>

      <section className="relative py-24 bg-gray-900 text-white overflow-hidden">
        <div 
          className="absolute inset-0 bg-cover bg-center z-0"
          style={{
            backgroundImage: "url('/images/web-development-bg2.jpg')",
            filter: "brightness(35%)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div className="absolute inset-0 bg-blue-500 z-10 mix-blend-overlay opacity-35"></div>
        <div className="container mx-auto px-4 max-w-6xl relative z-20">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <motion.div 
              className="mb-8 md:mb-0"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <h2 className="text-4xl font-bold mb-4">Let's work together</h2>
              <p className="text-xl max-w-2xl">
                We work with ecosystem leaders, corporations and startups worldwide. How can we help you?
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Link 
                to="/contact" 
                className="bg-[#10ac84] text-white py-3 px-8 rounded-lg text-lg font-semibold hover:bg-[#0d8b6a] transition duration-300"
              >
                Give us a shout
              </Link>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WebDevelopment;